.card {
    background: var(--terciario);
    width: 100%;
    height: fit-content;
    border-radius: .5rem;
    padding: 1rem;
    cursor: pointer;
}

.card .contDate {
    width: 100%;
    height: 4rem;
    /* background: #000; */
    display: flex;
    flex-direction: row;
}

.card .contDate .day {
    /* background: #e4a6a6; */
    width: 70%;
    height: 100%;
}

.card .contDate .classroom {
    /* background: #e4a6a6; */
    width: 70%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.card .contDate .contDesc .title {
    color: var(--cuarto);
    font-size: .7rem;
}

.card .contDate .contDesc .desc {
    color: var(--primario);
    font-size: .8rem;
    font-weight: bold;
}


.card .contHours {
    /* background: #7c889969; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.card .txtTitle {
    color: var(--cuarto);
    font-size: .7rem;
    margin: .5rem 0;
}

.card .contHours .hours {
    background: var(--fondo);
    width: 100%;
    height: 4.5rem;
    display: flex;
    flex-direction: row;
    border-radius: .5rem;
    padding: .5rem;
}

.card .contHours .hours .hrStart {
    background: var(--terciario);
    width: 50%;
    height: 100%;
    border-radius: .5rem;
    clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
}

.card .contHours .hours .hrEnd {
    /* background: #ec6262; */
    width: 50%;
    height: 100%;
}

.card .contHours .hours .contDesc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.card .contHours .hours .contDesc .title {
    color: var(--cuarto);
    font-size: .6rem;
}


.card .contHours .hours .contDesc .desc {
    color: var(--primario);
    font-size: .7rem;
}

.card .contHours .hours .hrStart .title {
    color: var(--primario);
}

.card .contHours .hours .hrStart .desc {
    color: var(--secundario);
}