.container {
  /* background: #017937; */
  width: 100%;
  height: fit-content;
}

.container .title {
  color: var(--primario);
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.container .subtitle {
  color: var(--cuarto);
  font-size: 0.7rem;
  font-weight: normal;
  margin-left: 3px;
  /* margin-bottom: .5rem; */
}

.container .txtArea {
  background: var(--terciario);
  width: 100%;
  min-height: 8rem;
  border: 1px solid var(--border);
  border-radius: 0.3rem;
  padding: 0.7rem;
  transition: all 0.3s ease;
  resize: none;
  overflow: hidden;
}

.container .txtArea::placeholder {
  color: #c0c0c0;
  font-size: 0.9rem;
}

.container .txtArea:focus {
  border: 1px solid var(--primario);
  /* box-shadow: 0 0 8px 0 #ececec; */
}

.container .txtWarning {
  color: var(--danger);
  font-size: 0.9rem;
  margin-top: 0.5rem;
}
