.container {
  background: var(--terciario);
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px dashed var(--cuarto);
  border-radius: 0.5rem;
  cursor: pointer;
  margin-bottom: 2rem;
}

.container .icon {
  color: var(--primario);
  font-size: 2.2rem;
}

.container .text {
  color: var(--primario);
}

.container .desc {
  color: var(--cuarto);
  font-size: 0.9rem;
}

.contPreview {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contFile {
  width: fit-content;
  display: flex;
  flex-direction: row;
}

.contFile .iconFile {
  background: var(--terciario);
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px 0 var(--cuarto);
}

.contFile .iconFile .icon {
  color: var(--danger);
  font-size: 2rem;
}

.contInfo {
  width: fit-content;
  height: 4rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem;
}

.contInfo .nameFile {
  color: var(--primario);
}

.contInfo .sizeFile {
  color: var(--cuarto);
  font-size: 0.8rem;
}

.preview {
  width: 4rem;
  height: 4rem;
  border-radius: 0.5rem;
}

.contPreview .contText {
  display: flex;
  flex-direction: column;
}

.contPreview .contText .txtError {
  color: var(--danger);
  font-size: 0.8rem;
  text-align: right;
}

.contPreview .contBtn {
  display: flex;
  column-gap: 1rem;
}

.contPreview .btnDelete {
  background: var(--danger);
  width: 1.8rem;
  height: 2rem;
  border: none;
  color: var(--terciario);
  border-radius: 0.5rem;
  font-size: 0.8rem;
  box-shadow: 0 2px 10px 0 var(--cuarto);
  transition: all 0.2s ease;
}

.contPreview .btnCut {
  background: var(--primario);
  width: 1.8rem;
  height: 2rem;
  border: none;
  color: var(--terciario);
  border-radius: 0.5rem;
  font-size: 0.8rem;
  box-shadow: 0 2px 10px 0 var(--cuarto);
  transition: all 0.2s ease;
}

.contPreview .btnDelete:hover {
  transform: translateY(-1px);
}

.contModal {
  /* background: #000; */
  width: 35vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
  padding: 0 1rem;
}

.contModal .contImage {
  width: fit-content;
  height: fit-content;
  overflow: scroll;
}

.contModal .contImage::-webkit-scrollbar {
  display: none;
}

.contModal .contImage .image {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
}

@media screen and (max-width: 620px) {
  .container {
    padding: 0 1rem;
  }
  
  .container .icon {
    color: var(--primario);
    font-size: 1.6rem;
  }
  
  .container .text {
    color: var(--primario);
    font-size: .8rem;
  }
  
  .container .desc {
    color: var(--cuarto);
    font-size: 0.8rem;
  }

  .contModal {
    width: 80vw;
    height: 80vh;
  }
}