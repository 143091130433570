.container {
  /* background: #000; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.container .contPrincipal {
  /* background: #ce3333; */
  width: calc(100% - 18rem);
  overflow: scroll;
  gap: 1rem;
}

.contPrincipal::-webkit-scrollbar {
  display: none;
}

.title {
  color: var(--primario);
  font-size: 1rem;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

/*------------------------------ WELCOME ------------------------------*/

.contPrincipal .welcome {
  /* background: var(--terciario); */
  width: 100%;
  margin-bottom: 1.3rem;
}

.contPrincipal .welcome .greet {
  color: var(--primario);
  font-size: 1.2rem;
}

.contPrincipal .welcome .greet .name {
  color: var(--secundario);
}

.contPrincipal .welcome .desc {
  color: var(--cuarto);
  font-size: 0.9rem;
}

/*------------------------------ SEMESTERS ------------------------------*/

.contPrincipal .semesters {
  background: var(--terciario);
  width: 100%;
  height: 17rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 1.3rem;
}

/*------------------------------ CLASS ------------------------------*/

.contPrincipal .contClass {
  /* background: var(--terciario); */
  /* background: #e64b4b; */
  width: 100%;
  height: fit-content;
  margin-bottom: 1.3rem;
}

.contHead {
  /* background: #f3f353; */
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: space-between;
}

.contPrincipal .contClass .contHead .desclink {
  color: var(--primario);
  font-size: 0.9rem;
}

.contPrincipal .contClass .contHead .desclink span {
  font-size: 0.7rem;
  margin-left: 0.5rem;
}

.contPrincipal .contClass .class {
  /* background: #000; */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.contPrincipal .contClass .addClass {
  background: var(--terciario);
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}

/*------------------------------ OTHER CLASS ------------------------------*/

.contPrincipal .otherClass {
  /* background: #f78484; */
  width: 100%;
  /* height: 10rem; */
  margin-bottom: 1.3rem;
}

.contPrincipal .otherClass .class {
  /* background: #000; */
  width: 100%;
  /* height: 10rem; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  /* border-radius: 1rem; */
}

.itemClass {
  background: #fff;
  border-radius: 1rem;
  overflow: hidden;
}
.itemClass .image {
  width: 100%;
  height: 4rem;
  object-fit: cover;
  object-position: center center;
}

.itemClass .dataClass {
  /* background: #e78c8c; */
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  padding: 1rem 1rem 0 1rem;
}

.itemClass .dataClass .contLevel {
  /* background: #09775c; */
  width: 1.5rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 0.5rem;
}

.itemClass .dataClass .contLevel .titleLevel {
  color: var(--cuarto);
  font-size: 0.6rem;
}

.itemClass .dataClass .contLevel .level {
  color: var(--primario);
  font-size: 0.8rem;
}

.itemClass .dataClass .contTitle {
  /* background: #09775c; */
  width: calc(100% - 2rem);
  height: 3rem;
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  border-left: 1px solid var(--border);
}

.itemClass .dataClass .contTitle .title {
  color: var(--primario);
  font-size: 0.8rem;
  font-family: "Lato", sans-serif;
}

.itemClass .dataClass .contTitle .desc {
  color: var(--cuarto);
  font-size: 0.7rem;
  font-family: "Lato", sans-serif;
  text-transform: capitalize;
}

.itemClass .infoClass {
  /* background: #e45e5e; */
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
}

.itemClass .infoClass .desc {
  color: var(--cuarto);
  font-size: .75rem;
  text-transform: capitalize;
}

.itemClass .contDays {
  /* background: #000; */
  width: 100%;
  padding: 0 1rem 1rem 1rem;
}

.itemClass .contDays .title {
  color: var(--primario);
  font-size: .8rem;
  font-weight: normal;
}

.itemClass .contDays .days {
  /* background: #ee8e8e; */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: .3rem;
}

.itemClass .contDays .days .day {
  /* width: 1.2rem;
  height: 1.2rem; */
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--cuarto);
  color: var(--primario);
}

.itemClass .contDays .days .day .desc {
  font-size: .7rem;
}

.itemClass .contDays .days .active {
  background: var(--primario);
  color: var(--terciario);
  border: 1px solid var(--primario);
}

.itemClass .qualification .average {
  background: var(--primario);
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--terciario);
}

/* .itemClass .note {
  font-size: .8rem;
} */

/*------------------------------ CONTENT SECONDARY ------------------------------*/

.container .contSecondary {
  background: #ffffff;
  width: 18rem;
}
