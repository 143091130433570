.container {
  background: var(--terciario);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.contImage {
  /* background: #db3535; */
  width: 50%;
  height: 100%;
  background: linear-gradient(150deg, #3776ff80, #282bc9),
    url("../../img/theme/fondoTecnm.jpeg");
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-clip-path: polygon(56% 0, 98% 29%, 86% 100%, 0 100%, 0 0);
  clip-path: polygon(56% 0, 98% 29%, 86% 100%, 0 100%, 0 0);
}

.contImage .image {
  width: 10rem;
  height: 10rem;
  animation: animationIcon 5s infinite;
}

@keyframes animationIcon {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}

.contForm {
  /* background: #ffffff; */
  width: 50%;
  height: 100%;
  /* padding: 0 2rem; */
}

.contForm .contTitle {
  /* background: #f5ca6d; */
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  padding: 0 2rem;
}

.contTitle .title {
  color: var(--primario);
  font-size: 0.9rem;
  font-weight: bold;
}

.contTitle .desc {
  color: var(--cuarto);
  font-size: 0.8rem;
}

.contForm .form {
  /* background: #5d5add; */
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1.5rem;
  /* position: relative; */
  /* padding: 2rem 0; */
}

.contForm .contCaptcha {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contForm .contCaptcha .descCaptcha {
  color: var(--cuarto);
  font-size: 0.8rem;
  text-align: center;
}

.contForm .contCaptcha .descCaptcha .desclink {
  color: var(--primario);
  font-size: 0.8rem;
}

@media screen and (max-width: 858px) {
  .contImage {
    width: 40%;
  }

  .contImage .image {
    width: 8rem;
    height: 8rem;
  }

  .contForm {
    width: 60%;
  }

  .contForm .contCaptcha .descCaptcha {
    font-size: 0.7rem;
  }

  .contForm .contCaptcha .descCaptcha .desclink {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 620px) {
  .container {
    height: 100vh;
    flex-direction: column;
    overflow-y: scroll;
    /* background: #000; */
  }

  .container::-webkit-scrollbar {
    display: none;
  }

  .contImage {
    /* background: #db3535; */
    width: 100%;
    height: 30vh;
    clip-path: none;
  }

  .contImage .image {
    width: 6rem;
    height: 6rem;
  }

  .contForm {
    /* background: #66d3b2; */
    width: 100%;
    height: fit-content;
    /* display: none; */
  }

  .contForm .form {
    /* background: #5d5add; */
    width: 100%;
    height: fit-content;
    min-height: 80vh;
  }

  .contForm .contCaptcha {
    /* background: #d3b666; */
    padding: 0 1rem;
  }
}
