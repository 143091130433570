.container {
	background: var(--terciario);
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	border-radius: .5rem;
	box-shadow: 0 5px 10px 0 #ececec;
}

.container .input {
	background: transparent;
	/* background: #d14a4a; */
	color: var(--primario);
	width: 85%;
	height: 100%;
	border: none;
	padding-left: .7rem;
	/* border-radius: .5rem 0 0 .5rem;s */
}

.container .contBtn {
	/* background: #b8f19d; */
	width: 15%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.container .contBtn .btnSearch {
	background: var(--primario);
	color: var(--terciario);
	width: 25px;
	height: 25px;
	border: 1px solid var(--primario);
	border-radius: .5rem;
	font-size: .8rem;
	cursor: pointer;
	transition: all .5s ease;
}

.container .contBtn .btnSearch:hover {
	background: transparent;
	color: var(--primario);
}