.btnOption {
  background: var(--terciario);
  width: 8rem;
  height: 3rem;
  border: none;
  color: var(--cuarto);
  font-size: 0.8rem;
  transition: all 0.5s ease;
}

.btnOption:hover {
  color: var(--primario);
}

.active {
  color: var(--primario);
  font-weight: bold;
}
