.option {
  background: var(--terciario);
  user-select: none;
}

/* Hide the browser's default checkbox */
.option input {
  position: absolute;
  cursor: pointer;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.option .checkmark {
  /* background: #e7d25a; */
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  padding: 1.2rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 1rem;
  -webkit-box-shadow: 0px 10px 13px -7px #00000036,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #00000036, 5px 5px 15px 5px rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: all 0.5s ease;
}

.option .checkmark:hover {
  background: var(--primario);
}

.option .checkmark .nameGroup {
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--border);
}

.option .checkmark .nameGroup .name {
  color: var(--primario);
  font-size: 0.9rem;
  font-weight: bold;
}

.option .checkmark .countGroup {
  text-align: center;
}

.option .checkmark .countGroup .desc {
  color: var(--cuarto);
  font-size: 0.8rem;
}

.option .checkmark .countGroup .count {
  color: var(--send);
  font-size: 1.3rem;
  line-height: 1.3rem;
}

/* When the checkbox is checked, add a blue background */
.option input:checked ~ .checkmark {
  background: var(--send);
  opacity: 1;
  transition: all 0.5s ease;
}

.option .checkmark:hover .nameGroup,
.option input:checked ~ .checkmark.nameGroup {
  border-color: var(--terciario);
}

.option .checkmark:hover .nameGroup .name,
.option input:checked ~ .checkmark .nameGroup .name {
  color: var(--terciario);
}

.option .checkmark:hover .countGroup .count,
.option input:checked ~ .checkmark .countGroup .count {
  color: var(--terciario);
}
