.contSwitch {
  width: fit-content;
  height: fit-content;
}

.switch {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 24px;
  position: relative;
  margin: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.sliderround {
  cursor: pointer;
  border-radius: 34px;
  width: 52px;
  height: 100%;
  border: 1px solid var(--border);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: flex;
  justify-content: center;
  text-align: end;
  flex-direction: column;
  padding: 0 8px 0 4px;
  color: var(--cuarto);
  font-size: 0.7rem;
}

.sliderround:before {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  background-color: var(--cuarto);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .sliderround {
  text-align: start;
  padding-left: 8px;
  border: 1px solid var(--primario);
  color: var(--primario);
}

input:checked + .sliderround:before {
  background-color: var(--primario);
}

input:checked + .sliderround:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
