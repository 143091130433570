.container {
  /* background: #f0c4c4; */
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.container::-webkit-scrollbar {
  display: none;
}

.contSelect {
  /* background: #ee8c8c; */
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.contButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.contTable {
  /* background: #000; */
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.contTable::-webkit-scrollbar {
  display: none;
}

.contTable .titleReticula {
  /* background: #ec7373; */
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contTable .titleReticula .carrera {
  color: var(--primario);
  font-size: 2rem;
  text-transform: uppercase;
}

.contTable .titleReticula .especialidad {
  color: var(--cuarto);
  font-size: 1.5rem;
}

.contTable .table {
  /* background: var(--terciario); */
  width: 100%;
  height: fit-content;
}

.table .headTable {
  /* background: #395aee; */
  width: 100%;
  height: 3rem;
}

.table .headTable .contColumn .columnFirst {
  background: var(--fondo);
  width: 1rem;
  color: var(--primario);
  font-size: 0.8rem;
  text-align: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}

.table .headTable .contColumn .column {
  background: var(--fondo);
  min-width: 7rem;
  color: var(--primario);
  font-size: 0.8rem;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 2;
}

.table .bodyTable {
  /* background: #4fe08b; */
  width: 100%;
  height: 90%;
}

.table .bodyTable tr td {
  height: 7rem;
  padding: 0.5rem;
}

.table .bodyTable tr .numRow {
  font-size: 0.7rem;
}

.table .bodyTable tr td .item {
  background: var(--terciario);
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px 0 #79797959;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.5s ease;
}

.table .bodyTable tr td .activo {
  background: linear-gradient(150deg, #324ef1, #233cca);
}

.table .bodyTable tr td .item:hover {
  background: var(--terciario);
  transform: scale(1.2, 1.2);
}

.table .bodyTable tr td .item .contName {
  /* background: #6af087; */
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: .5rem; */
}

.table .bodyTable tr td .item .contName .name {
  color: var(--terciario);
  font-size: 0.8rem;
  font-weight: bold;
}

.table .bodyTable tr td .item:hover .contName .name {
  color: var(--primario);
  font-weight: normal;
}

.table .bodyTable tr td .item .contNumber {
  /* background: var(--primario); */
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-radius: 0 0 .5rem .5rem;
	padding: 0 .5rem; */
}

.table .bodyTable tr td .item .number {
  color: var(--secundario);
  font-size: 0.6rem;
}

.table .bodyTable tr td .item .desc {
  color: var(--cuarto);
  font-size: 0.8rem;
}

.contModal {
  /* background: #000; */
  width: 80vw;
  height: 80vh;
  padding: 0 1rem;
}

@media screen and (max-width: 858px) {
  .container {
    height: fit-content;
  }

  .contTable {
    height: 90vh;
  }
}

@media screen and (max-width: 620px) {
  .container {
    padding: 0 1rem;
  }

  .contSelect {
    align-items: center;
    flex-direction: column;
    row-gap: 2rem;
  }

  .contTable::-webkit-scrollbar {
    display: none;
  }

  .contModal {
    width: 85vw;
    height: 85vh;
    padding: 0;
  }
}
