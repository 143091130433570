.container {
    background: var(--fondo);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container .image {
    background: var(--terciario);
    width: 8rem;
    height: 8rem;
    padding: 1.5rem;
    border-radius: 1rem;
    box-shadow: 0 10px 10px -5px #ccc;
}

.container .title {
    color: var(--primario);
    font-size: 2.3rem;
    margin-top: 2rem;
}

.container .desc {
    color: var(--cuarto);
    font-size: 1rem;
    margin-bottom: 5rem;
}
