/*---------------------- Contenedor principal ----------------------*/

.item {
  background: var(--terciario);
  width: 100%;
  height: 4rem;
  border-radius: 1rem;
  margin-bottom: 1.3rem;
  overflow: hidden;
  transition: height 0.5s ease-out;
}

/*---------------------- Clase pa expandir Item ----------------------*/

.itemFull {
  background: #fff;
  height: 26rem;
  transition: height 0.5s ease-in;
}

/*---------------------- Encabezado con información de la materia ----------------------*/

.item .head {
  /* background: #e48181; */
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  cursor: pointer;
}

.item .head .imgSubject {
  /* background: #dfb939; */
  width: 4.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0 1rem; */
}

.item .head .imgSubject .image {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  object-position: center top;
  border-radius: 0.8rem;
}

.item .head .nameSubject {
  /* background: #f57575; */
  width: calc(100% - 15rem);
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* padding: 0 .5rem; */
}

.item .head .nameSubject .title {
  /* background: #e47b7b; */
  color: var(--cuarto);
  font-size: 0.8rem;
  /* line-height: 1rem; */
}

.item .head .nameSubject .desc {
  color: var(--primario);
  font-size: 1rem;
  text-transform: capitalize;
  line-height: 1rem;
}

.item .head .statusSubject {
  /* background: #e47b7b; */
  width: 10rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item .head .statusSubject .status {
  font-size: 0.7rem;
  padding: 0.1rem 0.6rem;
  border-radius: 0.5rem;
}

.item .head .statusSubject .select {
  background: #d3fcd8;
  color: var(--success);
}

.item .head .statusSubject .noSelect {
  background: #fcd3d3;
  color: var(--danger);
}

/*---------------------- Espacio para la selección de grupo ----------------------*/

.selection {
  /* background: #e5392d; */
  width: 100%;
  height: 17rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 1rem 0;
}

.selection .contGroups {
  /* background: #f3eca2; */
  height: 15rem;
  grid-column: 1/3;
  border-right: 1px solid var(--border);
  /* padding-right: 1rem; */
}

.selection .contTitle {
  /* background: #e18080; */
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.selection .contGroups .contTitle .title {
  color: var(--black);
  font-size: 0.9rem;
}

.selection .contGroups .groups {
  /* background: #0bd70b; */
  width: 100%;
  height: 12rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc((100%) / 4), 1fr));
  grid-auto-flow: column;
  grid-auto-columns: minmax(calc(100% / 4), 1fr);
  grid-gap: 1rem;
  overflow-x: auto;
  padding: 1rem;
}

.selection .contGroups .groups::-webkit-scrollbar {
  background: var(--terciario);
  height: 8px;
}

/* Handle */
.selection .contGroups .groups::-webkit-scrollbar-thumb {
  background: var(--terciario);
  border-radius: 8px;
}

.selection .contGroups .groups:hover::-webkit-scrollbar-thumb {
  background: var(--border);
}

/*---------------------- Información del grupo ----------------------*/

.selection .infoClasroom {
  /* background: #e87878; */
  height: 15rem;
  padding: 0 1rem;
}

.selection .infoClasroom .members {
  /* background: #292e1e; */
  width: 100%;
  height: 12rem;
  padding: 0 1rem;
}

.selection .infoClasroom .members .title {
  color: var(--cuarto);
  font-size: 0.8rem;
}

/*---------------------- Información del docente ----------------------*/

.selection .infoClasroom .members .teacher {
  /* background: #ed3232; */
  height: calc(100% / 2);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.selection .infoClasroom .members .teacher .name {
  color: var(--primario);
  font-size: 1rem;
  text-transform: capitalize;
  line-height: 1rem;
  margin-top: 0.3rem;
}

.selection .infoClasroom .members .teacher .lastname {
  color: var(--primario);
  font-size: 0.8rem;
  text-transform: capitalize;
}

/*---------------------- Información de los estudiantes ----------------------*/

.selection .infoClasroom .members .students {
  /* background: #d0f2a9; */
  height: calc(100% / 2);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.selection .infoClasroom .members .students .member {
  /* background: #e1d577; */
  display: flex;
  flex-direction: row;
  margin-top: 0.3rem;
}

.selection .infoClasroom .members .students .member .profile {
  width: 2.4rem;
  height: 2.4rem;
}

.selection .infoClasroom .members .students .member .secondProfile {
  transform: translateX(-0.6rem);
}

.selection .infoClasroom .members .students .member .threeProfile {
  transform: translateX(-1.2rem);
}

.selection .infoClasroom .members .students .member .profile .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid var(--terciario);
}

.selection .infoClasroom .members .students .member .more {
  background: var(--terciario);
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid var(--terciario);
  transform: translateX(-1.8rem);
  box-shadow: 0 5px 10px 0px #0000001c;
}

.selection .infoClasroom .members .students .member .more .number {
  color: var(--black);
  font-size: 0.9rem;
}

/*---------------------- footer ----------------------*/

.footer {
  /* background: #f35252; */
  width: 100%;
  height: 5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.footer .groupSchedule {
  /* background: #e0ea83; */
  grid-column: 1/3;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  gap: 1rem;
}

.footer .groupSchedule .table {
  /* background: #f4c5c5; */
  width: 100%;
  height: 100%;
}

.footer .groupSchedule .table .headTable .contColumn .column {
  /* background: #9b97fe; */
  color: var(--cuarto);
  text-align: center;
  text-transform: capitalize;
  font-size: 0.8rem;
}

.footer .groupSchedule .table .bodyTable tr td {
  color: var(--black);
  text-align: center;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.footer .contButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer .contButton .btnAdd {
  background: var(--send);
  width: 10rem;
  height: 2rem;
  border: 1px solid var(--send);
  border-radius: 0.5rem;
  color: var(--terciario);
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.5s ease;
}

.footer .contButton .btnAdd:hover {
  background: transparent;
  color: var(--send);
  border: 1px solid var(--send);
}
