.contOption {
  /* background: #f3acac; */
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contOption .title {
  color: var(--primario);
  font-size: 1rem;
}

.contOption .btn {
  background: var(--terciario);
  color: var(--primario);
  border: none;
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  font-size: 0.7rem;
  box-shadow: -8px -8px 20px 0px #fff9, -6px -6px 6px 0px #fff9,
    8px 8px 20px 0px #0001, 5px 5px 6px 0px #0001, inset 0px 0px 0px 0px #fff9,
    inset 0px 0px 0px 0px #0001, inset 0px 0px 0px 0px #fff9,
    inset 0px 0px 0px 0px #0001;
  transition: all 0.5s ease;
}

.contOption .btnActive {
  background: var(--terciario);
  color: var(--success);
  box-shadow: 0px 0px 0px 0px var(--terciario), 0px 0px 0px 0px var(--terciario),
    0px 0px 0px 0px #0001, 0px 0px 0px 0px #0001,
    inset -8px -8px 20px 0px var(--terciario),
    inset -5px -5px 6px 0px var(--terciario), inset 2px 2px 15px 0px #0003,
    inset 3px 3px 3px 0px #0001;
  transition: all 0.5s ease;
}
