.AspirantStatistics-title {
  margin-bottom: 8px;
}

.AspirantStatistics-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.AspirantStatistics-searchBar {
  width: 300px;
  height: 40px;
}

.AspirantStatistics-total {
  margin-top: 12px;
}

.AspirantStatistics-total > .AspirantStatistics-aspirants {
  background-color: var(--lighter);
  display: inline-flex;
  padding: 4px 12px;
  border-radius: 4px;
  font-weight: bold;
}

.AspirantStatistics-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 12px;
  height: 70%;
  overflow: auto;
}

.AspirantStatistics-totalAspirant {
  margin-bottom: 8px;
}

.AspirantStatistics-aspirantCount {
  background-color: var(--secondary);
  padding: 4px 8px !important;
  margin-bottom: 8px !important;
  border-radius: 4px;
}

.AspirantStatistics-careerStatusTitle {
  text-transform: capitalize;
}
