/*------------------ ESTILOS DE ADDMATERIA ------------------*/
.contAddMateria {
  /* background: #d34141; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 2.5rem;
}

.contAddMateria .itemPrincipal {
  /* background: #000; */
  width: 100%;
  height: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.contAddMateria .itemPrincipal .infoPrincipal {
  background: var(--terciario);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
}

.contAddMateria .infoPrincipal .title {
  color: var(--cuarto);
  font-size: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.contAddMateria .infoPrincipal .desc {
  color: var(--primario);
  font-size: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.contAddMateria .contItem {
  /* background: #000; */
  width: 100%;
  height: 30%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1rem;
}

.contAddMateria .contItem .item {
  background: var(--terciario);
  padding: 0 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px 0 #ececec;
  position: relative;
}

.contAddMateria .contItem .item .contDesc {
  /* background: #36d8a7; */
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
}

.item .contDesc .desc {
  color: var(--primario);
  font-size: 2.5rem;
}

.item .contDesc .descText {
  color: var(--primario);
  font-size: 1rem;
}

.contAddMateria .contItem .item .contTitle {
  /* background: #cf6d11; */
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.item .contTitle .subtitle {
  color: var(--cuarto);
  font-size: 0.8rem;
}

.item .contTitle .title {
  color: var(--primario);
  font-size: 0.9rem;
  text-transform: capitalize;
  margin-top: -8px;
}

.contAddMateria .contItem .item .contIcon {
  background: var(--primario);
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: -1.25rem;
  right: 1rem;
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 10px 0 #cacaca;
}

.item .contIcon .icon {
  color: var(--terciario);
}

.contAddMateria .form {
  background: #ffffff;
  width: 50%;
  height: 100%;
  border-radius: 0.5rem;
}

@media screen and (max-width: 858px) {
  .contAddMateria .itemPrincipal {
    /* background: #d14d4d; */
    width: 100%;
    height: 20%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
  }

  .contAddMateria .itemPrincipal .infoPrincipal {
    background: var(--terciario);
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 0.5rem;
    border-radius: 0.5rem;
  }

  .contAddMateria .infoPrincipal .desc {
    font-size: 0.8rem;
  }

  .contAddMateria .contItem {
    /* background: #b0eb95; */
    width: 100%;
    height: 60%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}

@media screen and (max-width: 620px) {

	.contAddMateria {
		/* background: #000; */
		display: flex;
		flex-direction: column;
		overflow: scroll;
		row-gap: 1rem;
	}

  .contAddMateria .itemPrincipal {
		/* background: #e07171; */
    width: 100%;
    height: calc(100% - 15rem);
    grid-template-columns: repeat(1, 1fr);
  }

  .contAddMateria .itemPrincipal::-webkit-scrollbar,
  .contAddMateria .contItem::-webkit-scrollbar {
    display: none;
  }

  .contAddMateria .itemPrincipal .infoPrincipal {
    width: 100%;
    height: 100%;
    display: inline-flex;
    margin-right: 1rem;
  }

  .contAddMateria .contItem {
    /* background: #b0eb95; */
    width: 100%;
    height: 11rem;
		margin-bottom: 1rem;
    display: inline-block;
    white-space: nowrap;
    overflow: scroll;
  }

  .contAddMateria .contItem .item {
    display: inline-flex;
    width: 10rem;
    height: 9rem;
    flex-direction: column;
    vertical-align: middle;
    margin: 2rem 1rem 0 0;
  }
}
