.contTitle {
  /* background: #e27e7e; */
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-bottom: 2rem;
}

.title {
  color: var(--primario);
  font-size: 1.2rem;
  text-transform: capitalize;
}

.contTitle .contMenu {
  /* background: #000; */
  display: flex;
  flex-direction: row;
  column-gap: 3rem;
}

.linkPage {
  /* background: var(--secundario); */
  color: var(--primario);
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.linkPage:hover .iconLink {
  background: var(--primario);
  color: var(--terciario);
}

.linkPage .iconLink {
  background: var(--terciario);
  color: var(--primario);
  width: 1.5rem;
  height: 1.5rem;
  font-size: .7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.8rem;
  border-radius: 0.3rem;
  box-shadow: -8px -8px 20px 0px #fff9, -6px -6px 6px 0px #fff9,
    8px 8px 20px 0px #0001, 5px 5px 6px 0px #0001, inset 0px 0px 0px 0px #fff9,
    inset 0px 0px 0px 0px #0001, inset 0px 0px 0px 0px #fff9,
    inset 0px 0px 0px 0px #0001;
  transition: all 0.5s ease;
}

.contModal {
  /* background: #000; */
  width: 80vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 1rem;
}

@media screen and (max-width: 858px) {
  .contTitle {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 620px) {
  .contTitle {
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }

  .contModal {
    padding: 0;
  }
}
