.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.title {
  color: var(--primario);
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.titleItem {
  margin-bottom: 0.5rem;
}

.contForm {
  width: 100%;
  height: calc(100% - 2.2rem);
  overflow-y: scroll;
  /* padding-bottom: 3rem; */
}

.contForm::-webkit-scrollbar {
  display: none;
}

.contFormAlt {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.contBtn {
  /* background: #f38787; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
}

.contButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 0;
  padding: 0 1rem;
}

.formDate {
  width: 100%;
  height: fit-content;
}

.oneColumn {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  row-gap: 2rem;
}

.twoColumn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  row-gap: 2rem;
}

.threeColumn {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  row-gap: 2rem;
}

.fourColumn {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  row-gap: 2rem;
}

.inputLong {
  grid-column: 1/-1;
}

.inputMedium {
  grid-column: span 2;
}

.inputThree {
  grid-column: span 3;
}

.contTxtArea {
  /* background: #000; */
  margin-top: 1rem;
}

.contItem {
  padding-bottom: 1.5rem;
}

.contModal {
  /* background: #000; */
  width: 50vw;
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 1rem;
}

.modalSmall {
  width: 30vw;
}

.modalDay {
  /* background: #000; */
  width: 30vw;
  height: 70vh;
}

.modalSchedule {
  /* background: #d14242; */
  width: 30vw;
  height: 70vh;
}

.contDate .cards {
  /* background: #2dbccf; */
  width: 100%;
  /* height: 2rem; */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
}

@media screen and (max-width: 858px) {
  .container {
    background: var(--fondo);
    padding-top: 1rem;
    /* background: #f12727; */
    width: 100%;
    height: 90vh;
  }

  /* .title {
    font-size: .9rem;
  } */

  .contForm {
    width: 100%;
    height: calc(100% - 2.2rem);
    overflow-y: scroll;
    /* background: #000; */
  }

  .twoColumn {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .threeColumn {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .fourColumn {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .inputMedium {
    grid-column: 1/-1;
  }
}

@media screen and (max-width: 620px) {
  .title {
    font-size: 0.9rem;
    padding: 0 1rem;
  }

  .contForm {
    padding: 0 1rem;
  }

  .contModal {
    width: 80vw;
    height: 80vh;
    padding: 0;
  }
}
