.container {
  background: var(--terciario);
  width: 100%;
  height: fit-content;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
}

.clsCollapse {
  height: 4rem;
  overflow: hidden;
}

.container .header {
  /* background: #eba6a6; */
  width: 100%;
  height: 4rem;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.container .header .text {
  color: var(--primario);
  font-size: 1.2rem;
  font-weight: bold;
}

.container .header .contAction {
  /* background: #000; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.container .header .contAction .btn {
  background: var(--danger);
  width: 1.5rem;
  height: 1.8rem;
  border: none;
  border-radius: 0.4rem;
  color: var(--terciario);
  font-size: 0.8rem;
  font-weight: bold;
  margin-left: 1rem;
}

.container .header .open {
  animation: iconOpen 0.5s forwards;
}

@keyframes iconOpen {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
    transform: rotate(180deg);
  }
}

.container .header .close {
  animation: iconClose 0.5s forwards;
}

@keyframes iconClose {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container .content {
  /* background: #f5cece; */
  width: 100%;
  height: fit-content;
  padding: 2rem;
}

@media screen and (max-width: 620px) {
  .container .header .text {
    font-size: 1rem;
  }

  .container .header,
  .container .content {
    padding: 1.3rem;
  }
}
