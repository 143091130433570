.container {
  /* background: #be2323; */
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.container .contForm {
  /* background: #e48787; */
  width: 100%;
  height: calc(100% - 5.2rem);
  margin-top: 2rem;
  overflow: scroll;
}

.container .contForm::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 620px) {
  .container .contForm  {
    padding: 0 1rem;
  }
}