.container {
  /* background: #017937; */
  width: 100%;
  height: fit-content;
}

.container .title {
  color: var(--primario);
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.container .subtitle {
  color: var(--cuarto);
  font-size: 0.7rem;
  font-weight: normal;
  margin-left: 3px;
  /* margin-bottom: .5rem; */
}

.container .contToggle {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
}
