.container {
  background: var(--fondo);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  z-index: 2;
}

.image {
  width: 12rem;
  height: 12rem;
  animation: animationIcon 5s infinite;
}

@keyframes animationIcon {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
