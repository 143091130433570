.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.title {
  color: var(--primario);
  font-size: 1.2rem;
}

.classroom {
  width: 100%;
  height: calc(100% - 2.2rem);
  overflow-y: scroll;
  margin-top: 1rem;
}

.classroom::-webkit-scrollbar {
  display: none;
}

.classroom .searchClassroom {
  background: #ffffff;
  width: 100%;
  height: fit-content;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
}

.classroom .searchClassroom .headSection {
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.classroom .searchClassroom .headSection .contSearch {
  width: 25%;
  height: 2.5rem;
}

.classroom .searchClassroom .contClassroom {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  grid-gap: 1rem;
}

/*------------------ AssignedClassroom ------------------*/
/*------------------ AssignedClassroom ------------------*/

.classroom .assigned {
  width: 100%;
  height: fit-content;
  margin-top: 2rem;
}

.classroom .classroomAssigned {
  /* background: #e9a8a8; */
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin: 2rem 0;
}

.contAssigned {
  background: var(--terciario);
  /* background: #e78d8d; */
  width: 100%;
  height: 15rem;
  border-radius: 1rem;
  /* padding: 1rem; */
}

.contAssigned .contClassroom {
  /* background: #000; */
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
}

.contAssigned .contClassroom .nameClassroom {
  background: var(--fondo);
  /* background: #21c7b1; */
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--cuarto);
  border-radius: 1rem 0 1rem 0;
}

.contAssigned .contClassroom .nameClassroom .txtClassroom {
  color: var(--primario);
  font-size: 0.8rem;
  font-weight: bold;
}

.contAssigned .contClassroom .info {
  /* background: #12d1df; */
  width: 65%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  padding-left: 0.8rem;
}

.contAssigned .contClassroom .info .titleCard {
  color: var(--cuarto);
  font-size: 0.8rem;
}

.contAssigned .contClassroom .info .department {
  color: var(--primario);
  font-size: 0.9rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.contAssigned .contClassroom .contBtn {
  /* background: #c9e781; */
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contAssigned .contClassroom .contBtn .btnStatus {
  background: var(--terciario);
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  color: var(--danger);
}

.contAssigned .contGraph {
  /* background: #000; */
  width: 100%;
  height: 75%;
}

@media screen and (max-width: 858px) {
  .container {
    padding-top: 1rem;
  }

  .title {
    font-size: 1.1rem;
  }

  .classroom .searchClassroom .headSection .contSearch {
    width: 35%;
  }

  .classroom .searchClassroom .contClassroom {
    grid-template-columns: repeat(9, 1fr);
  }

  .classroom .classroomAssigned {
    /* background: #e9a8a8; */
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    margin: 2rem 0;
  }
}

@media screen and (max-width: 620px) {
  .container {
    /* background: #8dd4dd; */
    background: var(--terciario);
  }

  .title {
    margin-left: 1rem;
  }

  .classroom .searchClassroom {
    /* background: #eeec91; */
    width: 100%;
    height: fit-content;
    padding: 0;
    display: flex;
    flex-direction: column;
    border-radius: 0;
  }

  .classroom .searchClassroom .headSection {
    /* background: #d69061; */
    height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 1rem;
    row-gap: 1rem;
  }

  .classroom .searchClassroom .headSection .title {
    margin: 0;
  }

  .classroom .searchClassroom .headSection .contSearch {
    width: 100%;
  }

  .classroom .searchClassroom .contClassroom {
    /* background: #ec7c7c; */
    width: 100%;
    height: fit-content;
    display: inline-block;
    overflow: scroll;
    white-space: nowrap;
    padding: 1rem;
  }

  .classroom .searchClassroom .contClassroom::-webkit-scrollbar {
    display: none;
  }

  .classroom .assigned {
    background: var(--fondo);
    /* background: #a8f1b2; */
    width: 100%;
    height: fit-content;
    margin-top: 0;
    padding: 1rem 0;
    border-top-left-radius: 2rem;
  }

  .classroom .assigned .title {
    margin: 0.5rem 0 1.5rem 1rem;
  }

  .classroom .classroomAssigned {
    /* background: #e9a8a8; */
    display: inline-block;
    overflow: scroll;
    white-space: nowrap;
    margin: 0;
    padding: 0 1rem;
  }

  .classroom .classroomAssigned::-webkit-scrollbar {
    display: none;
  }

  .contAssigned {
    background: var(--terciario);
    /* background: #eee7a7; */
    width: 90%;
    height: 15rem;
    display: inline-flex;
    flex-direction: column;
    border-radius: 1rem;
    margin-right: 1rem;
  }
}
