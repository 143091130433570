.welcome {
  background: var(--terciario);
  width: 70%;
  height: 10rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  padding: 0 2rem;
}

@media screen and (max-width: 858px) {
  .container {
    padding-top: 1.5rem;
  }

  .welcome {
    column-gap: 1rem;
    padding: 0 1rem;
  }
}

@media screen and (max-width: 620px) {

  .container {
    padding: 1rem;
  }

  .welcome {
    /* background: #075664; */
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    row-gap: 1rem;
  }
}
