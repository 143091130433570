.header {
  /* background: #e97d7d; */
  width: 100%;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.header .title {
  color: var(--primario);
  font-size: 1.2rem;
  /* margin-bottom: 1rem; */
}

.header .action {
  /* background: #000; */
  display: flex;
  gap: 1rem;
}

.header .action .search {
  /* background: #000; */
  width: 17rem;
  height: 40px;
}

.content {
  width: 100%;
  height: calc(100% - 3.5rem);
  overflow: scroll;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 1rem 0;
}

.content::-webkit-scrollbar {
  display: none;
}
