.chart {
  /* background: #000; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.indicator {
  /* background: #e98e8e; */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  color: var(--cuarto);
  user-select: none;
}
