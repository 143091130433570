.contInput {
  color: var(--cuarto);
  /* background: #f09898; */
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  margin: 0;
}

.contInput .input {
  opacity: 0;
  height: 0;
  width: 0;
}

.checkmark {
  background: var(--terciario);
  height: 1rem;
  width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3rem;
  margin-right: 0.5rem;
  box-shadow: 0 5px 10px 0 #dbdbdb;
  transition: all 0.5s ease;
}

.contInput .input:checked ~ .checkmark {
  background: var(--primario);
}

.checkmark .icon {
	color: var(--secundario);
	font-size: .6rem;
	opacity: 0;
}

.contInput input:checked ~ .checkmark .icon {
  opacity: 1;
}

.txtLink {
  color: var(--primario);
  margin-left: 2px;
}

.container .txtWarning {
  color: var(--danger);
  font-size: 0.8rem;
  margin-top: 0.5rem;
}