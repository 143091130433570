.form {
  /* background: #000; */
  width: 65%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0;
}

.form .title {
  color: var(--cuarto);
  font-size: 0.9rem;
  margin: 2rem 0;
}

.form .contAction {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.form .contAction .descLog {
  color: var(--cuarto);
  font-size: 0.85rem;
  cursor: pointer;
  transition: all 0.5s ease;
}

.form .contAction .desc:hover {
  color: var(--primario);
}

@media screen and (max-width: 620px) {
  .form {
    /* background: #000; */
    width: 100%;
    padding: 1rem;
  }
}
