.container {
  /* background: #000; */
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  column-gap: 1.5rem;
}

.contImage {
  background: var(--terciario);
  width: 10rem;
  height: 12rem;
  /* border: 2px dashed var(--primario); */
}

.contImage .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  /* border-radius: 0.5rem; */
  box-shadow: 0 10px 10px -6px #4f4f4f;
}

.contInfo {
  width: calc(100% - 10rem);
}

.contInfo .info {
  display: flex;
  flex-direction: row;
  column-gap: 0.4rem;
  margin-bottom: 0.5rem;
}

.contInfo .info .text {
  color: var(--primario);
  font-size: 0.9rem;
  text-align: justify;
}

.contInfo .info .text::first-letter {
  text-transform: uppercase;
}

@media screen and (max-width: 620px) {
  .container {
    /* background: #f5f0a8; */
    display: flex;
    flex-direction: column-reverse;
    column-gap: 0;
    row-gap: 1.5rem;
  }

  .contImage {
    background: var(--terciario);
    width: 100%;
    height: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px dashed var(--primario); */
  }

  .contImage .image {
    width: 10rem;
    height: 12rem;
    object-fit: cover;
    object-position: center center;
    /* border-radius: 0.5rem; */
    box-shadow: 0 10px 10px -6px #4f4f4f;
  }

  .contInfo {
    width: 100%;
  }
}
