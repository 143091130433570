.btn {
  /* width: 100%;
    height: 2.5rem; */
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  cursor: pointer;
  transition: all 0.2s ease;
}


.btn:hover {
  transform: translateY(-1px);
}

.btnPrincipal {
  background: var(--primario);
  border: 1px solid var(--primario);
  color: var(--terciario);
  box-shadow: 0 0 10px 0 var(--cuarto);
}

.btnSecondary {
  background: var(--terciario);
  border: 1px solid var(--terciario);
  color: var(--primario);
  box-shadow: 0 0 10px 0 #ececec;
}

.btnDanger {
  background: var(--danger);
  border: 1px solid var(--danger);
  color: var(--terciario);
  box-shadow: 0 0 10px 0 #ffcaca;
}

