@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
:root {
  --primario: #1b396a;
  --secundario: #f47b20;
  --terciario: #ffffff;
  --cuarto: #bbc3ec;

  --fondo: #f8f9fe;
  --border: #e0e0e0;
  --danger: #fd4a4a;
  --success: #16bd3f;
  --form: #725b10;
  --register: #999999;
  --send: #084de2;
  --caution: #edc525;
  --observaciones: #5b08e2;
  --black: #000;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

h1,
p,
table {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Lato", sans-serif;
  scroll-behavior: smooth;
  /* overflow: hidden; */
}

body::-webkit-scrollbar {
  display: none;
}

.container {
  background: var(--terciario);
  width: 100%;
  height: 100vh;
}

.contFixed {
  background: var(--terciario);
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.header {
  background: var(--terciario);
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: row;
}

.main {
  background: var(--primario);
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap;
  overflow: hidden; */
}

.main .contActivity {
  background: var(--fondo);
  /* width: 55%; */
  width: 95vw;
  height: 100%;
  border-radius: 2rem 0 0 0;
  padding: 2rem 2rem 0 2rem;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  box-shadow: 0 0 10px 0 #666666;
}

.main .contentFull {
  padding: 0;
}

.main .contInfo {
  background: var(--primario);
  width: 20vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem 1rem 1rem;
}

.main .contInfo .cont {
  background: var(--primario);
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}

.btnMenu {
  background: transparent;
  border: none;
  width: 3rem;
  height: 3rem;
  color: var(--terciario);
  font-size: 2rem;
}

@media screen and (max-width: 1024px) {
  .main .contActivity {
    width: 94vw;
  }
}

@media screen and (max-width: 858px) {
  .container {
    height: fit-content;
  }

  .main {
    /* background: #000; */
    flex-direction: column;
    height: fit-content;
  }

  .main .contActivity {
    /* background: #a7e92c; */
    width: 100%;
    min-height: 100vh;
    border-radius: 0;
    padding: 4rem 2rem 0 2rem;
    box-shadow: none;
    /* background: #eb5d5d; */
  }
}

@media screen and (max-width: 620px) {
  .btnMenu {
    font-size: 1.5rem;
  }

  .main .contActivity {
    height: fit-content;
    padding: 10vh 0 0 0;
    /* background: var(--secundario); */
  }
}
