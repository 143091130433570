.container {
  /* background: #0df073; */
  width: 100%;
  height: fit-content;
  /* border-radius: 0.3rem; */
}

.container .title {
  color: var(--primario);
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.container .subtitle {
  color: var(--cuarto);
  font-size: 0.7rem;
  font-weight: normal;
  margin-left: 3px;
  /* margin-bottom: .5rem; */
}

.container .input {
  /* background: var(--terciario); */
  width: 100%;
  height: 46px;
  border: 1px solid var(--border);
  border-radius: 0.3rem;
  padding: 0 0.7rem;
  transition: all 0.3s ease;
}

.container .contSelect {
  background: var(--terciario);
  padding: 0;
  cursor: pointer;
  border-radius: 0.3rem;
}

.container .contFilter {
  background: var(--terciario);
  padding: 0;
  cursor: pointer;
  border-radius: 0.3rem;
  height: 40px;
  border: 1px solid var(--terciario);
  box-shadow: 0 5px 10px 0 #ececec;
}

.contSelect:focus-within,
.contFilter:focus-within {
  border: 1px solid var(--primario);
}

.container .input::placeholder {
  color: #c0c0c0;
  font-size: 0.9rem;
}

.container .input:focus {
  border: 1px solid var(--primario);
  /* box-shadow: 0 0 8px 0 #ececec; */
}

.container .txtWarning {
  color: var(--danger);
  font-size: 0.9rem;
  margin-top: 0.5rem;
}
