.Toggle {
  --toggle-size: 18px;
  width: 50px;
  height: calc(var(--toggle-size) + 6);
  border-radius: 100px;
  cursor: pointer;
  padding: 3px;
  background-color: var(--primario);
}

.Toggle__Circle {
  --toggle-size: 18px;
  width: var(--toggle-size);
  height: var(--toggle-size);
  border-radius: 100px;
  background-color: var(--terciario);
  transition: transform 300ms ease-in;
}
