.card {
  background: var(--primario);
  /* background: #e1ffba; */
  width: fit-content;
  height: 5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--secundario);
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 1rem;
  box-shadow: 0 7px 10px 0 #bfbfbf;
  transition: all 0.5s ease;
  cursor: pointer;
}

.card .contIcon {
  background: var(--fondo);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card .contIcon .icon {
  color: var(--primario);
  animation: animationIcon .8s infinite;
}

@keyframes animationIcon {
    0% {
        transform: rotateZ(20deg);
        transform-origin: top center;
    }
    100% {
        transform: rotateZ(-15deg);
        transform-origin: top center;
    }
}

.card .info {
  /* background: #f1bebe; */
  width: calc(100% - 2.5rem);
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 1rem;
}

.card .info .title {
  color: var(--secundario);
  font-size: 1rem;
  font-weight: bold;
  /* text-transform: uppercase; */
}

.card .info .text {
  color: var(--terciario);
  font-size: 0.8rem;
}
