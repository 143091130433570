.container {
  /* background: #f7d4d4; */
  width: 100%;
  height: calc(100% - 4rem);
}

.container .contTable {
  /* background: #dfa3a3; */
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.container .contTable::-webkit-scrollbar {
  display: none;
}

.container .contTable .table {
  /* background: #f9f1bc; */
  width: 100%;
  height: fit-content;
  border-collapse: separate;
  border-spacing: 0 1rem;
  table-layout: fixed;
  margin: -1rem 0;
  padding: 0 1rem;
  overflow-x: hidden;
}

.container .contTable .table .headTable {
  background: var(--terciario);
  /* background: #a46bda; */
  width: 100%;
  height: 2rem;
  position: sticky;
  top: 0;
}

.container .contTable .table .headTable .contColumn {
  /* background: #eb5a5a; */
  width: fit-content;
}

.container .contTable .table .headTable .contColumn .column {
  /* background: #b0e1d0; */
  color: var(--cuarto);
  width: calc(23rem / 7);
  height: 100%;
  text-align: center;
  text-transform: capitalize;
  font-size: 0.8rem;
}

.container .contTable .table .headTable .contColumn .columnSubject {
  /* background: #9c6e05; */
  width: calc(22rem - (23rem / 7));
  /* width: 22rem; */
  height: 100%;
}

.container .contTable .table .bodyTable {
  /* background: #f02dcd; */
  width: 100%;
  height: calc(100% - 2rem);
}

.container .contTable .table .bodyTable .contColumn {
  /* background: #c23535; */
  user-select: none;
}

.container .contTable .table .bodyTable .contColumn .column {
  /* background: #e7a7a7; */
  text-align: center;
  height: 3.3rem;
}

.container .contTable .table .bodyTable .contColumn .column .day .hour {
  color: var(--black);
  font-size: 0.8rem;
  line-height: 0.8rem;
}

.container .contTable .table .bodyTable .contColumn .column .day .classroom {
  color: var(--cuarto);
  font-size: 0.8rem;
}

.container .contTable .table .bodyTable .contColumn .day .classroom {
  color: var(--cuarto);
  font-size: 0.7rem;
}

.container .contTable .table .bodyTable .contColumn .column .infoGroup {
  /* background: #cc3030; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  justify-content: center;
  flex-direction: row;
}

.container
  .contTable
  .table
  .bodyTable
  .contColumn
  .column
  .infoGroup
  .contImage {
  /* background: #b5f0e1; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.container
  .contTable
  .table
  .bodyTable
  .contColumn
  .column
  .infoGroup
  .contImage
  .image {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  object-position: center top;
  border-radius: 0.8rem;
}

.container
  .contTable
  .table
  .bodyTable
  .contColumn
  .column
  .infoGroup
  .contName {
  /* background: #d8d82a; */
  width: calc(100% - 2.5rem);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 1rem;
}

.container
  .contTable
  .table
  .bodyTable
  .contColumn
  .column
  .infoGroup
  .contName
  .name {
  color: var(--black);
  font-size: .9rem;
  text-transform: capitalize;
  line-height: .9rem;
}

.container
  .contTable
  .table
  .bodyTable
  .contColumn
  .column
  .infoGroup
  .contName
  .teacher {
  color: var(--cuarto);
  font-size: 0.8rem;
  text-transform: capitalize;
}

.container .contTable .table .bodyTable .contColumn .column .group {
  background: var(--send);
  width: 2rem;
  height: 2rem;
  color: var(--terciario);
  font-size: 0.7rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .contTable .table .bodyTable .contColumn .column .btnDelete {
  background: var(--terciario);
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid var(--danger);
  border-radius: 0.5rem;
  color: var(--danger);
  font-size: 0.7rem;
  transition: all 0.5s ease;
}

.container .contTable .table .bodyTable .contColumn .column .btnDelete:hover {
  background: var(--danger);
  color: var(--terciario);
}

.container .contButton {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .contButton .btnSave {
  background: var(--send);
  width: 10rem;
  height: 2rem;
  border: 1px solid var(--send);
  border-radius: 0.5rem;
  color: var(--terciario);
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.5s ease;
}

.container .contButton .btnSave:hover {
  background: transparent;
  color: var(--send);
  border: 1px solid var(--send);
}
