.container {
  /* background: var(--terciario); */
  width: 100%;
  height: 100%;
}

.container .header {
  /* background: #e2a625; */
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container .header .contFilter {
  /* background: #db6363; */
  width: fit-content;
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 1rem;
}

.container .header .contFilter .btnFilter {
  background: var(--terciario);
  color: var(--primario);
  width: 1.8rem;
  height: 1.8rem;
  font-size: 0.8rem;
  border: none;
  border-radius: 50%;
  box-shadow: 0 5px 10px 0 #ececec;
}

.container .header .contControl {
  /* background: #22a8ac; */
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.container .header .contControl .contSearch {
  /* background: #db6363; */
  width: 17rem;
  height: 2.5rem;
}

.container .header .contControl .btnAdd {
  background: var(--primario);
  color: var(--terciario);
  font-size: 0.9rem;
  border: 1px solid var(--primario);
  border-radius: 0.5rem;
  padding: 0 0.7rem;
  transition: all 0.5s ease;
}

.container .header .contControl .btnAdd .icon {
  background: var(--terciario);
  color: var(--primario);
  font-size: 0.8rem;
  padding: 0.2rem 0.3rem;
  margin-right: 0.7rem;
  border-radius: 0.3rem;
}

.container .header .contControl .btnAdd:hover {
  background: transparent;
  color: var(--primario);
  border: 1px solid var(--primario);
}

.container .contTable {
  /* background: var(--terciario); */
  /* background: #dd2222; */
  width: 100%;
  height: calc(100% - 4rem);
  /* padding: 0 1rem; */
  border-radius: 0.5rem 0.5rem 0 0;
  overflow-y: scroll;
}

.container .contTable::-webkit-scrollbar {
  display: none;
}

.table {
  /* background: #f3ecc7; */
  width: 100%;
  height: fit-content;
  border-collapse: separate;
  border-spacing: 0 1rem;
  margin: -1rem 0;
}

.table .headTable {
  background: var(--fondo);
  width: 100%;
  height: 3.5rem;
  position: sticky;
  top: 0;
}

.table .headTable .contColumn .column {
  color: var(--primario);
  font-size: 1rem;
  text-align: center;
}

.container .table .headTable .contColumn .column:nth-child(2) {
  text-align: left;
  padding-left: 1rem;
}

.table .bodyTable {
  /* background: #9737f1; */
  width: 100%;
  height: calc(100% - 3.5rem);
}

.table .bodyTable tr {
  /* background: #ffffff; */
  border: 0.5rem;
  margin-bottom: 1rem;
  padding: 1rem 0;
  cursor: pointer;
}

.container .table .bodyTable tr {
  cursor: pointer;
  /* background: #000; */
}

.container .table .bodyTable tr td {
  background: var(--terciario);
  color: var(--primario);
  height: 4rem;
  font-size: 1rem;
  /* font-weight: 300; */
  text-align: center;
}

.container .table .bodyTable tr:hover td {
  background: var(--primario);
  color: var(--terciario);
}

.container .table .bodyTable tr td:nth-child(1) {
  border-radius: 1rem 0 0 1rem;
}

.container .table .bodyTable tr td:nth-child(2) {
  text-transform: capitalize;
  text-align: left;
  padding-left: 1rem;
}

.container .table .bodyTable tr td:nth-last-child(1) {
  border-radius: 0 1rem 1rem 0;
}

.container .table .bodyTable tr td .btnAction {
  background: var(--primario);
  color: var(--terciario);
  width: 1.7rem;
  height: 1.7rem;
  font-size: 0.7rem;
  border: none;
  border-radius: 0.6rem;
}

.container .table .bodyTable tr:hover td .btnAction {
  background: var(--terciario);
  color: var(--primario);
}