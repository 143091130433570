.contDesc {
  /* background: #000; */
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contDesc .title {
  color: var(--primario);
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 1rem;
}

.contDesc .desc {
  color: var(--cuarto);
  font-size: 0.9rem;
  font-weight: normal;
}

.contImg {
  /* background: #000; */
  width: 40%;
  height: 100%;
  padding: 1rem 0;
}

.contImg .image {
  /* background: #f5bebe; */
  width: 100%;
  height: 100%;
}

.contDesc .title span,
.contDesc .desc span {
  color: var(--secundario);
  font-weight: bold;
}

@media screen and (max-width: 858px) {
  .contDesc {
    padding: 1rem 0;
  }

  /* .contDesc .title {
    font-size: 0.8rem;
    margin-bottom: 0.9rem;
  }
  .contDesc .desc {
    font-size: 0.8rem;
  } */
}

@media screen and (max-width: 620px) {
  .contDesc {
    width: 100%;
    height: fit-content;
    padding: 0;
  }

  .contDesc .title {
    font-size: 0.9rem;
  }

  .contDesc .desc {
    font-size: 0.8rem;
  }

  .contImg {
    width: 100%;
    height: 6rem;
  }
}
