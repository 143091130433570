.CareerCard {
  background-color: var(--lighter);
  padding: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.CareerCard * {
  margin: 0;
  padding: 0;
}

.CareerCard-title {
  font-size: 12pt;
}

.CareerCard-info {
  display: flex;
  justify-content: space-between;
  font-size: 9pt;
}

.CareerCard-info p {
  font-size: inherit;
  margin-bottom: 4px;
}
