.contCard {
  background: var(--terciario);
  /* background: #dcecb7; */
  width: 100%;
  height: 4rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  overflow: hidden;
  transition: all 0.5s ease;
}

.cardComplete {
  width: 100%;
  height: 20rem;
  /* box-shadow: 0 0 20px 0 #dfdfdf; */
  transition: all 0.5s ease;
}

.contCard .header {
  /* background: #ee9c9c; */
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  padding: 0 1rem;
  cursor: pointer;
}

.contCard .header .contIcon {
  background: var(--fondo);
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}

.contCard .header .contIcon .icon {
  color: var(--primario);
  font-size: 0.8rem;
}

.contCard .header .contIcon .view {
  color: var(--success);
}

.contCard .header .contInfo {
  /* background: #9cc05a; */
  width: calc(100% - 2rem);
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0.7rem;
}

.contCard .header .contInfo .title {
  color: var(--primario);
  font-size: 0.8rem;
}

.contCard .header .contInfo .title::first-letter {
  text-transform: uppercase;
}

.contCard .infoNote {
  /* background: #ebdbaf; */
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 1rem;
}

.contCard .infoNote .desc {
  color: var(--primario);
  font-size: 0.9rem;
}

.contCard .contDocument {
  /* background: #ebdbaf; */
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
}

.contCard .contDocument .document {
  background: var(--fondo);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem;
  border-radius: 1rem;
  cursor: pointer;
}

.contCard .contDocument .document .title {
  color: var(--cuarto);
  font-size: 0.6rem;
}

.contCard .contDocument .document .nameDoc {
  color: var(--primario);
  font-size: 0.8rem;
}

.contCard .contDocument .document .nameDoc::first-letter {
  text-transform: uppercase;
}

.contCard .date {
  /* background: #e9963e; */
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem;
}

.contCard .date .desc {
  color: var(--cuarto);
  font-size: 0.7rem;
}

.contCard .date .desc::first-letter {
  text-transform: uppercase;
}

.viewNoti {
  background: var(--terciario);
}

.noviewNoti {
  background: var(--cuarto);
}