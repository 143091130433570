.container {
  width: 100%;
  height: 5rem;
  /* background: #000; */
}

.contInput {
  background: var(--terciario);
  width: 100%;
  height: 2.7rem;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 10px 0 #ececec;
  border-radius: 0.5rem;
}

.contInput .contIcon {
  /* background: #8edcf1; */
  width: 2.7rem;
  height: 2.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contInput .contIcon .icon {
  color: var(--cuarto);
  font-size: 0.9rem;
  cursor: pointer;
}

.contInput .input {
  /* background: #b8ebcd; */
  width: calc(100% - (2.7rem * 2));
  height: 100%;
  border: none;
  padding-right: 0.7rem;
}

.input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--cuarto);
  font-size: 0.9rem;
  opacity: 1; /* Firefox */
}

.input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--cuarto);
  font-size: 0.9rem;
}

.input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--cuarto);
  font-size: 0.9rem;
}

.container .txtWarning {
  color: var(--danger);
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

@media screen and (max-width: 620px) {
  /* .container {
    width: 100%;
    height: 5rem;
  }
  
  .contInput {
    background: var(--terciario);
    width: 100%;
    height: 2.3rem;
    display: flex;
    flex-direction: row;
    box-shadow: 0 0 10px 0 #ececec;
    border-radius: 0.5rem;
  }
  
  .contInput .contIcon {
    width: 2.3rem;
    height: 2.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contInput .contIcon .icon {
    color: var(--cuarto);
    font-size: 0.9rem;
    cursor: pointer;
  }
  
  .contInput .input {
    width: calc(100% - (2.3rem * 2));
    height: 100%;
    border: none;
    padding-right: 0.7rem;
  } */
}