.contCheck {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 0.5rem;
  margin-top: 2rem;
  transition: all 1s ease;
}

.contCheck .contIcon {
  background: var(--success);
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}

.contCheck .contIcon .icon {
  color: var(--terciario);
  font-size: 0.7rem;
}

.contCheck .text {
  color: var(--success);
  font-size: .9rem;
}
