.container {
  /* background: #017937; */
  width: 100%;
  height: fit-content;
}

.container .title {
  color: var(--primario);
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.container .subtitle {
  color: var(--cuarto);
  font-size: 0.7rem;
  font-weight: normal;
  margin-left: 3px;
  /* margin-bottom: .5rem; */
}

.contInput {
  background: var(--terciario);
  /* background: #000; */
  width: 100%;
  height: 46px;
  display: flex;
  flex-direction: row;
  border: 1px solid var(--border);
  border-radius: 0.3rem;
  transition: all .3s ease;
}

.contInput:focus-within {
  border: 1px solid var(--primario);
}

.contInput .btn {
  background: var(--terciario);
  width: 2.7rem;
  height: 2.7rem;
  color: var(--primario);
  font-size: 0.9rem;
  border: none;
  border-radius: 0.3rem;
}

.contInput .input {
  /* background: #b8ebcd; */
  width: calc(100% - 2.7rem);
  height: 100%;
  border: none;
  padding: 0 0.7rem;
  border-radius: 0.3rem;
}

.input:focus ~ .contInput {
  border: 1px solid var(--primario);
  background: #c92323;
}

.input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--cuarto);
  font-size: 0.9rem;
  opacity: 1; /* Firefox */
}

.input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--cuarto);
  font-size: 0.9rem;
}

.input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--cuarto);
  font-size: 0.9rem;
}

.container .txtWarning {
  color: var(--danger);
  font-size: 0.8rem;
  margin-top: 0.5rem;
}
