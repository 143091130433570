.contAula {
  /* background: var(--cuarto); */
  width: 4rem;
  height: 6rem;
  border-radius: 0.8rem;
  /* box-shadow: 0 0 10px 0 #ececec; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.5s ease;
}

.contAula .aula {
  background: var(--terciario);
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.9rem;
  box-shadow: -8px -8px 20px 0px #fff9, -6px -6px 6px 0px #fff9,
    8px 8px 20px 0px #0001, 5px 5px 6px 0px #0001, inset 0px 0px 0px 0px #fff9,
    inset 0px 0px 0px 0px #0001, inset 0px 0px 0px 0px #fff9,
    inset 0px 0px 0px 0px #0001;
  transition: all 0.5s ease;
}

.contAula:hover .aula {
  box-shadow: 0px 0px 0px 0px var(--terciario), 0px 0px 0px 0px var(--terciario),
    0px 0px 0px 0px #0001, 0px 0px 0px 0px #0001,
    inset -8px -8px 20px 0px var(--terciario),
    inset -5px -5px 6px 0px var(--terciario), inset 2px 2px 15px 0px #0003,
    inset 3px 3px 3px 0px #0001;
}

.contAula .aula .desc {
  color: var(--primario);
  font-size: 0.8rem;
  font-weight: bold;
}

.contAula .contText {
  /* background: #000; */
  width: 100%;
  height: calc(100% - 3rem);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contAula .contText .title {
  color: var(--primario);
  font-size: 0.8rem;
}

.contModal {
  /* background: #000; */
  width: 40vw;
  height: 70vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 1rem 5rem 0 5rem;
}

@media screen and (max-width: 620px) {
  .contAula {
    display: inline-flex;
  }
}

@media screen and (max-width: 858px) {
  .contModal {
    /* background: #000; */
    width: 60vw;
  }
}

@media screen and (max-width: 620px) {
  .contModal {
    /* background: #000; */
    width: 80vw;
    padding: 1rem;
  }
}
