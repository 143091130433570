.container {
  background: var(--terciario);
  width: 100%;
  height: 100vh;
}

.container .contError {
  /* background: #c2ee99; */
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container .contError .error {
  /* background: #d4510a; */
  width: 100%;
  height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 1rem;
}

.container .contError .error .contDesc {
  /* background: #e66666; */
  width: 12rem;
  height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .contError .error .contDesc .text {
  color: var(--primario);
  font-size: 12rem;
}

.container .contError .image {
  width: 12rem;
  height: 12rem;
  animation: animationIcon 5s infinite;
}

@keyframes animationIcon {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}

.container .desc {
  color: var(--primario);
  font-size: 1rem;
}

.container .contBtn {
  /* background: #c2ee99; */
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* row-gap: 1rem; */
}

@media screen and (max-width: 620px) {
  .container .contError .error {
    height: 12rem;
    margin-bottom: 0;
  }

  .container .contError .error .contDesc {
    width: 7rem;
    height: 7rem;
  }

  .container .contError .error .contDesc .text {
    font-size: 7rem;
  }

  .container .contError .image {
    width: 7rem;
    height: 7rem;
  }
}
