.contNav {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
}

.contNav .contOption {
  background: var(--terciario);
  width: fit-content;
  height: 3.2rem;
  padding: 0 1rem;
  border-radius: 1rem;
  white-space: nowrap;
}

.contNav .contOption .btnOption {
  background: var(--terciario);
  width: 8rem;
  height: 3rem;
  border: none;
  color: var(--cuarto);
  font-size: 0.8rem;
  transition: all 0.5s ease;
}

.contNav .contOption .btnOption:hover {
  color: var(--primario);
}

.contNav .contOption .active {
  color: var(--primario);
  font-weight: bold;
}

.contNav .contOption .contSelect {
  background: var(--primario);
  width: 5rem;
  height: 0.2rem;
}

@media screen and (max-width: 1171px) {
  .contNav {
    /* background: #000; */
    justify-content: flex-start;
    padding: 0 1rem;
    margin-top: 1rem;
  }

  .contNav .contOption {
    /* background: #da3131; */
    width: 100%;
    display: inline-block;
    overflow: scroll;
    white-space: nowrap;
  }

  .contNav .contOption::-webkit-scrollbar {
    display: none;
  }
}