.container {
  /* background: #f0c4c4; */
  width: 100%;
  height: 100%;
  /* overflow: scroll; */
}

.contTitle {
  /* background: #eb4646; */
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contTitle .title {
  color: var(--primario);
  font-size: 1.2rem;
  /* text-transform: capitalize; */
}

.contTitle .contActions {
  /* background: #e2f5b0; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  column-gap: 1rem;
}

.contTitle .contActions .actFilter {
  /* background: #7dd86b; */
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.contTitle .contActions .onlyPaidFilter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.contTitle .contActions .btnFilter {
  background: var(--terciario);
  color: var(--primario);
  width: 1.8rem;
  height: 1.8rem;
  font-size: 0.8rem;
  border: none;
  border-radius: 50%;
  box-shadow: 0 5px 10px 0 #ececec;
}

.contTitle .contActions .contSearch {
  /* background: #000; */
  width: 17rem;
  height: 40px;
}

.contTable {
  /* background: #ec4b4b; */
  width: 100%;
  height: 85%;
  overflow-y: scroll;
}

.contTable::-webkit-scrollbar {
  display: none;
}

.contTable .table {
  /* background: #f3ecc7; */
  width: 100%;
  height: fit-content;
}

.contTable .table .headTable {
  width: 100%;
  height: 3rem;
}

.table .headTable .contColumn .column {
  background: var(--fondo);
  font-size: 0.9rem;
  font-weight: bold;
  position: sticky;
  top: 0;
}

.table .headTable .contColumn .alignText {
  text-align: center;
}

.contTable .table .bodyTable {
  /* background: #6bb7da; */
  width: 100%;
  height: 90%;
}

.contTable .table .bodyTable tr td .contRow {
  background: var(--terciario);
  height: 4rem;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.contTable .table .bodyTable tr:hover .contRow {
  background: var(--primario);
}

.contTable .table .bodyTable tr td .contRow .text {
  color: var(--cuarto);
  text-transform: capitalize;
  font-size: 0.9rem;
}

.contTable .table .bodyTable tr td .contRow .txtStatus {
  width: 5.7rem;
  text-align: center;
  padding: 0.12rem 0;
  border-radius: 0.8rem;
  font-size: 0.7rem;
}

.contTable .table .bodyTable tr td .contRow .aceptado,
.contTable .table .bodyTable tr:hover td .contRow .aceptado {
  background: #d3fcd8;
  color: var(--success);
}

.contTable .table .bodyTable tr td .contRow .aspirante,
.contTable .table .bodyTable tr:hover td .contRow .aspirante {
  background: #d3fcd8;
  color: var(--success);
}

.contTable .table .bodyTable tr td .contRow .rechazado,
.contTable .table .bodyTable tr:hover td .contRow .rechazado {
  background: #fcd3d3;
  color: var(--danger);
}

.contTable .table .bodyTable tr td .contRow .registrado,
.contTable .table .bodyTable tr:hover td .contRow .registrado {
  background: var(--register);
  color: #f7f7f7;
}

.contTable .table .bodyTable tr td .contRow .formularios,
.contTable .table .bodyTable tr:hover td .contRow .formularios {
  background: #f5d32a;
  color: var(--form);
}

.contTable .table .bodyTable tr td .contRow .enviado,
.contTable .table .bodyTable tr:hover td .contRow .enviado {
  background: #8faffa;
  color: var(--send);
}

.contTable .table .bodyTable tr td .contRow .revisado,
.contTable .table .bodyTable tr:hover td .contRow .revisado {
  background: #ffe4cf;
  color: var(--secundario);
}

.contTable .table .bodyTable tr td .contRow .observaciones,
.contTable .table .bodyTable tr:hover td .contRow .observaciones {
  background: #ceb6ff;
  color: var(--observaciones);
}

.contTable .table .bodyTable tr td .contRow .corregido,
.contTable .table .bodyTable tr:hover td .contRow .corregido {
  background: #ffa7ff;
  color: var(--primario);
}

.contTable .table .bodyTable tr td .contRow .pagar,
.contTable .table .bodyTable tr:hover td .contRow .pagar {
  background: #ffe4cf;
  color: var(--secundario);
}

.contTable .table .bodyTable tr td .contRow .pagado,
.contTable .table .bodyTable tr:hover td .contRow .pagado {
  background: #01b6a4;
  color: var(--terciario);
}

.contTable .table .bodyTable tr:hover .contRow .text {
  color: var(--terciario);
}

.contTable .table .bodyTable tr td .contRow .contIcon {
  /* background: #97e26b; */
  width: fit-content;
  height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.5rem;
  cursor: pointer;
}

.contTable .table .bodyTable tr td .contRow .contIcon .icon {
  background: var(--terciario);
  width: 2rem;
  height: 2rem;
  border: none;
  color: var(--primario);
  font-size: 0.8rem;
  border-radius: 50%;
  transition: all 0.5s ease;
}

.contTable .table .bodyTable tr:hover td .contRow .contIcon .icon {
  color: var(--primario);
}

.contTable .table .bodyTable tr td .contStart {
  border-radius: 0.5rem 0 0 0.5rem;
}

.contTable .table .bodyTable tr .columIcon {
  width: 10rem;
}

.contTable .table .bodyTable tr td .contEnd {
  border-radius: 0 0.5rem 0.5rem 0;
}

.contTable .table .bodyTable tr td .contText {
  justify-content: center;
}

.contModal {
  /* background: #000; */
  width: 80vw;
  height: 80vh;
  padding: 0 1rem;
  position: relative;
}

.contModal .contBtnAction {
  background: var(--terciario);
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  padding: 0.3rem;
  border-radius: 2rem;
  position: fixed;
  left: calc(50% - 7.5rem);
  bottom: 1rem;
  box-shadow: 0 0 10px 0 #00000056;
  transition: all 0.5s ease;
}

.contModal .contBtnAction .comment {
  background: var(--primario);
  color: var(--terciario);
  width: 8rem;
  height: 2.2rem;
  font-size: 0.9rem;
  border-radius: 2rem;
  border: 1px solid var(--primario);
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
  transition: all 0.3s ease;
}

.contModal .contBtnAction .comment:hover {
  background: transparent;
  color: var(--primario);
  border: 1px solid var(--primario);
}

.contModal .contBtnAction .btnIcon {
  background: var(--terciario);
  color: var(--primario);
  width: 2.2rem;
  height: 2.2rem;
  font-size: 0.9rem;
  border-radius: 2rem;
  border: none;
  transition: all 0.3s ease;
}

.contModal .contBtnAction .btnIcon:hover {
  color: var(--secundario);
}

.modalNote {
  /* background: #000; */
  width: 40vw;
  height: fit-content;
  padding: 0 1rem;
}

@media screen and (max-width: 858px) {
  .container {
    height: fit-content;
  }

  .contTitle {
    height: 12vh;
  }

  .contTitle .title {
    font-size: 1rem;
  }

  .contTitle .contSearch {
    width: 35%;
    height: 40px;
  }

  .contTable {
    height: 90vh;
  }

  .table .headTable .contColumn .column {
    font-size: 0.8rem;
  }

  .contTable .table .bodyTable tr td .contRow .text {
    font-size: 0.8rem;
  }

  .contTable .table .bodyTable tr td .contRow .txtStatus {
    padding: 0.12rem 0.4rem;
    font-size: 0.6rem;
  }

  .contTable .table .bodyTable tr td .contRow .contIcon .icon {
    width: 1.3rem;
    height: 1.3rem;
    font-size: 0.7rem;
  }

  .contModal {
    overflow: scroll;
  }

  .contModal::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 620px) {
  .contTitle {
    height: fit-content;
    padding: 1rem;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .contTitle .title {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  .contTitle .contActions {
    /* background: #ca5151; */
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column-reverse;
    row-gap: 1rem;
  }

  .contTitle .contActions .actFilter {
    /* background: #7dd86b; */
    width: 100%;
  }

  .contTitle .contActions .contSearch {
    width: 100%;
    height: 40px;
    /* background: #000; */
  }

  .contTable {
    width: 100%;
    height: 73vh;
    /* background: #f38484; */
  }

  .contTable .table {
    width: fit-content;
    height: fit-content;
    /* display: none; */
  }

  .table .headTable .contColumn .column {
    width: fit-content;
    white-space: nowrap;
    font-size: 0.9rem;
    padding: 0 1rem;
  }

  .contTable .table .bodyTable tr td .contRow {
    white-space: nowrap;
    padding: 0 1rem;
    border-radius: 0;
  }

  .contModal {
    /* background: #f37a7a; */
    width: 85vw;
    height: 85vh;
    padding: 0;
    overflow: scroll;
  }

  .contModal::-webkit-scrollbar {
    display: none;
  }

  .modalNote {
    width: 85vw;
    height: 50vh;
    padding: 0;
  }
}
