.container {
  /* background: #017937; */
  width: 100%;
  height: fit-content;
}

.container .title {
  color: var(--primario);
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.container .subtitle {
  color: var(--cuarto);
  font-size: 0.7rem;
  font-weight: normal;
  margin-left: 3px;
  /* margin-bottom: .5rem; */
}

.container .toggleInput {
  width: 100%;
  height: 46px;
  display: flex;
	flex-direction: row;
	border: 1px solid var(--border);
	border-radius: .3rem;
}

.container .contToggle {
  width: 20%;
  height: 100%;
  display: flex;
	justify-content: center;
  align-items: center;
	border-right: 1px solid var(--border);
}

.container .toggleInput .input {
	width: 80%;
  height: 100%;
	border: none;
  padding: 0 0.7rem;
	border-radius: 0 .3rem .3rem 0;
}

.container .toggleInput .input::placeholder {
  color: #c0c0c0;
  font-size: 0.9rem;
}

.container .txtWarning {
  color: var(--danger);
  font-size: 0.9rem;
  margin-top: 0.5rem;
}