.form {
  /* background: #ec3131; */
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form .title {
  color: var(--cuarto);
  font-size: 0.9rem;
  margin-bottom: 2rem;
}

.form .twoColumn {
  /* background: #078667; */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.form .contAction {
  /* background: #f59393; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-bottom: 2rem;
}

.form .contAction .descLog {
  color: var(--cuarto);
  font-size: 0.85rem;
  cursor: pointer;
  transition: all 0.5s ease;
}

.form .contAction .desc:hover {
  color: var(--primario);
}

@media screen and (max-width: 620px) {
  .form {
    /* background: #196d56; */
    width: 100%;
    padding: 1rem;
  }

  .form .title {
    color: var(--cuarto);
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  .form .twoColumn {
    /* background: #06135e; */
    display: flex;
    flex-direction: column;
    row-gap: 0;
  }

  .form .contAction {
    /* background: #f59393; */
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* align-items: center; */
    margin-bottom: 2rem;
  }

  .form .contAction .descLog {
    color: var(--cuarto);
    font-size: 0.85rem;
    cursor: pointer;
    transition: all 0.5s ease;
  }

  .form .contAction .desc:hover {
    color: var(--primario);
  }
}
