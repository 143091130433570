.header {
  /* background: #e97d7d; */
  width: 100%;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.header .title {
  color: var(--primario);
  font-size: 1.2rem;
  /* margin-bottom: 1rem; */
}

.header .action {
  /* background: #000; */
  display: flex;
  gap: 1rem;
}

.header .action .search {
  /* background: #000; */
  width: 17rem;
  height: 40px;
}

.contSubject {
  width: 100%;
  height: calc(100% - 3.5rem);
  overflow: scroll;
}

.contSubject::-webkit-scrollbar {
  display: none;
}

.contItem {
  /* background: var(--primario); */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-auto-flow: row;
    grid-auto-rows: minmax(15rem,1fr); */
  gap: 1rem;
  padding: 1rem 0;
}

.item {
  background: var(--terciario);
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 1rem;
}

.item .headItem {
  background: var(--terciario);
  width: 100%;
  display: flex;
  align-items: center;
  /* border-bottom: 1px solid var(--border); */
}

.item .headItem .contImage {
  /* background: #000; */
  width: 3.5rem;
  height: 3.5rem;
}

.item .headItem .contImage .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  border-radius: 1rem;
}

.item .headItem .info {
  /* background: #d15959; */
  width: calc(100% - 3.5rem);
  height: 3.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 0.8rem;
  overflow: hidden;
}

.item .headItem .info .name {
  color: var(--primario);
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.item .headItem .info .key {
  color: var(--cuarto);
  font-size: 0.75rem;
  text-transform: uppercase;
}

.item .headItem .info .key .icon {
  margin-right: 0.5rem;
}

.item .data {
  background: var(--terciario);
  /* background: #9ae6ad; */
  width: 100%;
  height: fit-content;
}

.item .data .infoSubject {
  width: 100%;
  /* height: 10rem; */
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.contDesc {
  /* background: #e9aeae; */
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}

.contDesc .contIcon {
  /* background: var(--terciario); */
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}

.contDesc .contIcon .icon {
  color: var(--primario);
}

.contDesc .contText .title {
  color: var(--cuarto);
  font-size: 0.8rem;
}

.contDesc .contText .desc {
  color: var(--primario);
  font-size: 0.8rem;
}

.item .data .qualification {
  /* background: #e69a9a; */
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--border);
  padding-top: 0.5rem;
}

.item .data .qualification .title {
  color: var(--cuarto);
  font-size: 0.8rem;
}

.item .data .qualification .desc {
  color: var(--primario);
  font-size: 0.8rem;
}

.item .data .qualification .desc span {
  color: var(--caution);
  margin-right: 0.25rem;
}
