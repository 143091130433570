.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.contInfo {
  background: var(--terciario);
  /* background: #df0c0c; */
  width: 75%;
  height: 100%;
  border-radius: 1rem 1rem 0 0rem;
  padding-bottom: 5rem;
  overflow: scroll;
  /* box-shadow: 0 0 10px 0 var(--cuarto); */
}

.contInfo::-webkit-scrollbar {
  display: none;
}

/*------------------- ESTILOS DEL BANNER -------------------*/

.banner {
  background: linear-gradient(180deg, #3776ff80, #282bc9),
    url("../../img/theme/fondoTecnm.jpeg");
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 15rem;
  border-radius: 1rem 1rem 0 0;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.banner .infoProfile {
  background: var(--terciario);
  /* background: #e68989; */
  width: 100%;
  height: 5rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: row;
}

.banner .infoProfile .imgProfile {
  /* background: var(--success); */
  width: 8rem;
  /* height: 100%; */
  position: relative;
}

.banner .infoProfile .imgProfile .image {
  width: 8rem;
  height: 8rem;
  position: absolute;
  top: -3rem;
  border-radius: 50%;
  object-fit: cover;
  /* border: 1px solid var(--primario); */
}

.banner .infoProfile .dataProfile {
  /* background: var(--success); */
  /* background: #e5f0a7; */
  width: calc(100% - 8rem);
  height: 100%;
  position: relative;
  padding: 0 1rem;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
  position: relative;
}

.banner .infoProfile .dataProfile .profileDesc {
  /* background: #067245; */
  width: 100%;
  height: 5rem;
}

.banner .infoProfile .dataProfile .name {
  color: var(--primario);
  font-size: 1.2rem;
  text-transform: capitalize;
}

.banner .infoProfile .dataProfile .contDesc {
  /* background: #ec8787; */
  display: flex;
  flex-direction: column;
}

.banner .infoProfile .dataProfile .descStatus::after {
  content: "|";
  margin: 0 0.5rem;
}

.banner .infoProfile .dataProfile .desc {
  color: var(--cuarto);
  font-size: 0.8rem;
}

.banner .infoProfile .dataProfile .otherDesc {
  /* background: #db7c7c; */
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  column-gap: 0.7rem;
  position: absolute;
  top: -3rem;
}

.banner .infoProfile .dataProfile .otherDesc .contCurp {
  /* background: #f0a2a2; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  border-right: 1px solid var(--border);
  padding-right: 0.7rem;
}

.banner .infoProfile .dataProfile .otherDesc .title {
  background: var(--secundario);
  color: var(--primario);
  width: fit-content;
  font-size: 0.6rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0 0.5rem;
  border-radius: 0.3rem;
}

.banner .infoProfile .dataProfile .otherDesc .desc {
  color: var(--terciario);
}

/*------------------- IMAGEN PREDETERMINADA DEL PERFIL -------------------*/

.banner .infoProfile .imgProfile .profileDefault {
  background: var(--fondo);
  width: 8rem;
  height: 8rem;
  position: absolute;
  top: -3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.banner .infoProfile .imgProfile .profileDefault .icon {
  /* background: #000; */
  color: var(--cuarto);
  font-size: 6rem;
  position: absolute;
  bottom: 0;
  line-height: 0;
}

/*------------------- ESTILOS DEL INFOPRINCIPAL -------------------*/

.infoPrincipal {
  /* background: #000; */
  width: 100%;
  height: 4rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.infoPrincipal .itemBanner {
  /* background: #dbeaff; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 0.5rem;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid var(--border);
}

.infoPrincipal .itemBanner .title {
  color: var(--cuarto);
  font-size: 0.8rem;
}

.infoPrincipal .itemBanner .desc {
  color: var(--primario);
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*------------------- ESTILOS DEL INFORMATION GENERAL -------------------*/

.infoGral {
  /* background: #000; */
  width: 100%;
  height: 25rem;
  padding: 0 2rem;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    "relevant highSchool highSchool"
    "relevant entity address";
  grid-gap: 1rem;
}

/*------------------- ESTILOS DE DATOS GENERALES /RELEVANTES-------------------*/

.infoGral .relevant {
  grid-area: relevant;
  /* border-right: 1px solid var(--border); */
  /* background: var(--cuarto); */
}

.contTitle {
  /* background: #e62525; */
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  /* border-bottom: 1px solid var(--border); */
}

.contTitle .titleCard {
  color: var(--primario);
  font-weight: bold;
}

.infoGral .relevant .contData {
  /* background: #f19696; */
  width: 100%;
  height: calc(100% - 3rem);
  padding: 0.5rem;
  overflow: scroll;
}

.infoGral .relevant .contData::-webkit-scrollbar {
  display: none;
}

.dataGral {
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.dataGral .title {
  color: var(--cuarto);
  font-size: 0.8rem;
}

.dataGral .title::first-letter {
  text-transform: uppercase;
}

.dataGral .desc {
  color: var(--primario);
  font-size: 0.8rem;
  text-transform: capitalize;
}

/*------------------- ESTILOS DE DATOS DE PREPARATORIA-------------------*/

.infoGral .highSchool {
  grid-area: highSchool;
  /* background: #f8e5e5; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  padding-top: 1rem;
}

.highSchool .school {
  /* background: #f1bb73; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.highSchool .title {
  color: var(--cuarto);
  font-size: 0.8rem;
}

.highSchool .school .nameSchool {
  color: var(--primario);
  font-size: 1rem;
  text-transform: capitalize;
}

.highSchool .school .codeSchool {
  color: var(--cuarto);
  font-size: 0.8rem;
}

.highSchool .ubication .icon {
  color: var(--cuarto);
  font-size: 0.7rem;
  margin-right: 0.2rem;
}

.highSchool .ubication .desc {
  color: var(--cuarto);
  font-size: 0.8rem;
}

.highSchool .date {
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
}

.highSchool .date .graduation {
  /* background: #2e2525; */
  width: fit-content;
  height: 30%;
}

.highSchool .date .graduation .title {
  font-size: 0.8rem;
}

.highSchool .date .graduation .desc {
  color: var(--primario);
  font-size: 0.9rem;
}

.highSchool .date .average {
  /* background: #2e2525; */
  width: 60%;
  height: 4rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 0.5rem;
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  border: 1px solid var(--border);
}

.highSchool .date .average .contNumber {
  background: var(--primario);
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.highSchool .date .average .contNumber .desc {
  color: var(--terciario);
  font-size: 0.9rem;
  font-weight: bold;
}

.highSchool .date .average .contTitle {
  /* background: #000; */
  width: calc(100% - 3rem);
}

.highSchool .date .average .contTitle .title {
  color: var(--cuarto);
  font-size: 0.9rem;
  line-height: 0.9rem;
}

.itemDesc .title {
  color: var(--cuarto);
  font-size: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.itemDesc .desc {
  color: var(--primario);
  font-size: 0.8rem;
  text-transform: capitalize;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*------------------- ESTILOS DEL ENTITY -------------------*/

.infoGral .entity {
  grid-area: entity;
  /* background: #bad4fc; */
}

/*------------------- ESTILOS DEL ADRESS -------------------*/

.infoGral .address {
  grid-area: address;
  /* background: #000; */
}

/*------------------- ESTILOS DE CARD LOCATION -------------------*/

.contLocation {
  background: linear-gradient(to top, #ffffff, #ffffffc4),
    url("../../img/theme/map.jpeg");
  background-position: center center;
  background-size: cover;
  /* filter: grayscale(100%); */
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  padding: 1rem;
}

.contLocation .contTitle {
  /* background: #a02121; */
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
}

.contLocation .contTitle .icon {
  color: var(--primario);
  font-size: 1.2rem;
  margin-right: 1rem;
}

.contLocation .contTitle .title {
  color: var(--primario);
}

.contLocation .contText {
  /* background: #f7c8c8; */
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
}

.contLocation .contText .desc {
  color: var(--primario);
  font-size: 0.8rem;
}

/*------------------- ESTILOS INFOECONOMIC -------------------*/

.infoEconomic {
  /* background: #f5a3a3; */
  width: 100%;
  height: fit-content;
  padding: 1rem 2rem;
}

.infoEconomic .dataEconomic {
  /* background: #f3f2c9; */
  width: 100%;
  height: calc(100% - 3rem);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

/*------------------- ESTILOS DE CONTACTFILE -------------------*/

.contFile {
  /* background: var(--cuarto); */
  width: 100%;
  height: 25vh;
  padding: 1rem 2rem;
}

.contFile .viewFile {
  /* background: #f3c9c9; */
  width: 100%;
  height: calc(100% - 3rem);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

/*------------------- ESTILOS DEL CONTACT -------------------*/

.contContact {
  /* background: #f76868; */
  width: 25%;
  height: 100%;
  padding: 0 0 1rem 1rem;
}

.contContact .contTitle {
  /* background: #c0f397; */
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
}

.contContact .contTitle .title {
  color: var(--primario);
  font-weight: bold;
}

.contContact .contTutor {
  /* background: #689af7; */
  width: 100%;
  height: 40%;
}

.contContact .contTutor .contItems {
  /* background: #f3b197; */
  width: 100%;
  height: 90%;
  padding: 1rem 0;
  overflow: scroll;
}

.contContact .contTutor .contItems::-webkit-scrollbar {
  display: none;
}

/*------------------- ESTILOS DE TUTOR -------------------*/

.tutor {
  background: var(--terciario);
  /* background: #000; */
  width: 100%;
  height: 7rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  border-radius: 0.5rem;
  padding: 1rem;
}

.tutor .contImage {
  background: var(--cuarto);
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0 0 10px 0 #ececec; */
  overflow: hidden;
}

.tutor .contImage .image {
  width: 2rem;
  height: 2rem;
}

.tutor .infoTutor {
  /* background: #ecf5cc; */
  width: calc((100% - 2rem));
  height: 100%;
  padding-left: 1rem;
}

.tutor .infoTutor .kinship {
  color: var(--cuarto);
  font-size: 0.7rem;
  text-transform: capitalize;
  transition: all 0.5s ease;
}

.tutor .infoTutor .name {
  color: var(--primario);
  font-size: 0.9rem;
  text-transform: capitalize;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tutor .infoTutor .desc {
  color: var(--cuarto);
  font-size: 0.7rem;
  text-transform: capitalize;
  /* display: none;
  opacity: 0; */
  transition: all 0.5s ease;
}

.contContact .contact {
  /* background: #68f793; */
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.contContact .contact .infoContact {
  background: var(--terciario);
  width: 100%;
  height: 88%;
  border-radius: 1rem;
  padding: 0.5rem 0;
  overflow: scroll;
}

.contContact .contact .infoContact::-webkit-scrollbar {
  display: none;
}

/*------------------- ESTILOS DE ITEMALT EN DOCUMENTACIÓN Y CONTACT -------------------*/

.itemAlt .item {
  /* background: #dbeaff; */
  width: 100%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 1rem;
  overflow: hidden;
  border-radius: 0.5rem;
}

.itemAlt .item .itemIcon {
  background: var(--terciario);
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  box-shadow: 0 0 10px 0 #ececec;
}

.itemAlt .item .itemIcon .icon {
  color: var(--primario);
  font-size: 1.2rem;
}

.itemAlt .item .itemText {
  /* background: #e97070; */
  width: calc(100% - 3rem);
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem;
}

.itemAlt .item .title {
  color: var(--cuarto);
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemAlt .item .desc {
  color: var(--primario);
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1024px) {
  /*------------------- ESTILOS DEL BANNER -------------------*/

  .container {
    padding-top: 2rem;
  }

  .banner {
    height: 13rem;
  }

  .banner .infoProfile {
    height: 5rem;
  }

  .banner .infoProfile .imgProfile {
    width: 7rem;
  }

  .banner .infoProfile .imgProfile .image {
    width: 7rem;
    height: 7rem;
  }

  .banner .infoProfile .imgProfile .profileDefault {
    width: 7rem;
    height: 7rem;
  }

  .banner .infoProfile .dataProfile {
    width: calc(100% - 7rem);
  }

  /*------------------- ESTILOS DE CARD LOCATION -------------------*/

  .contLocation {
    padding: 0.6rem;
  }

  .contLocation .contTitle .icon {
    color: var(--primario);
    font-size: 1rem;
    margin-right: 0.6rem;
  }

  .contLocation .contTitle .title {
    font-size: 0.9rem;
  }

  /*------------------- ESTILOS DE ITEMALT EN DOCUMENTACIÓN Y CONTACT -------------------*/

  .itemAlt .item {
    height: 4rem;
    padding: 0 0.7rem;
  }

  .itemAlt .item .itemIcon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .itemAlt .item .itemIcon .icon {
    font-size: 1rem;
  }

  .itemAlt .item .itemText {
    /* background: #e97070; */
    width: calc(100% - 2.5rem);
    padding: 0 0.7rem;
  }

  /*------------------- ESTILOS DE TUTOR -------------------*/

  .tutor {
    height: 5rem;
    padding: 0 0.7rem;
  }

  .tutor .contImage {
    width: 2.5rem;
    height: 2.5rem;
  }

  .tutor .infoTutor {
    width: calc((100% - 2.5rem));
    padding-left: 0.7rem;
  }
}

@media screen and (max-width: 858px) {
  .container {
    height: fit-content;
    flex-direction: column;
  }

  .highSchool .date .average {
    width: 100%;
  }

  .contInfo {
    background: var(--terciario);
    width: 100%;
    height: fit-content;
    /* overflow: scroll; */
  }

  .contContact {
    background: var(--terciario);
    /* background: #e76363; */
    width: 100%;
    height: fit-content;
    padding: 0 2rem 2rem 2rem;
    border-radius: 0 0 1rem 1rem;
    margin-bottom: 2rem;
  }

  .contContact .contTutor {
    /* background: #3b65a5; */
    /* width: 100%; */
    height: fit-content;
  }

  .contContact .contTutor .contItems {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5rem;
  }

  .contContact .contTutor .tutor {
    /* width: 100%; */
    height: fit-content;
    border-radius: 0;
    padding: 0.5rem;
    overflow: hidden;
  }

  .contContact .contTutor .tutor .infoTutor {
    /* background: #c2a607; */
    padding: 0.5rem;
  }

  .contContact .contact {
    grid-column: span 2;
    height: 23rem;
  }

  .contContact .contact .infoContact {
    border-radius: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .contContact .contact .infoContact .item {
    /* background: #000; */
    width: 14rem;
    padding: 0;
    border-radius: 0;
  }
}

@media screen and (max-width: 620px) {
  .container {
    padding: 0 0 1rem 0;
  }

  .contBanner {
    padding: 1rem;
  }

  .banner {
    height: 20rem;
    margin: 0;
  }

  .banner .infoProfile {
    /* background: #e1f1c3; */
    height: fit-content;
    padding: 4rem 0 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .banner .infoProfile .imgProfile {
    width: 8rem;
  }

  .banner .infoProfile .imgProfile .image {
    width: 8rem;
    height: 8rem;
    top: -8rem;
    border: 5px solid var(--terciario);
  }

  .banner .infoProfile .imgProfile .profileDefault {
    width: 8rem;
    height: 8rem;
    top: -8rem;
    border: 5px solid var(--terciario);
  }

  .banner .infoProfile .dataProfile {
    /* background: #ce951b; */
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    text-align: center;
    margin-top: 1rem;
  }

  .banner .infoProfile .dataProfile .name {
    color: var(--primario);
    font-size: 1rem;
    text-transform: capitalize;
  }

  .banner .infoProfile .dataProfile .contDesc {
    flex-direction: column;
  }

  .banner .infoProfile .dataProfile .descStatus::after {
    content: "";
    margin: 0;
  }

  .banner .infoProfile .dataProfile .desc {
    color: var(--cuarto);
    font-size: 0.8rem;
  }

  .banner .infoProfile .dataProfile .otherDesc {
    /* background: #db7c7c; */
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 1rem 1rem 0 1rem;
    column-gap: 0.7rem;
    position: relative;
    top: 0;
  }

  .banner .infoProfile .dataProfile .otherDesc .contCurp {
    /* background: #f0a2a2; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border-right: none;
    padding-right: 0.7rem;
  }

  .banner .infoProfile .dataProfile .otherDesc .title {
    color: var(--primario);
    /* font-size: 0.7rem; */
  }

  .banner .infoProfile .dataProfile .otherDesc .title {
    background: transparent;
    font-size: 0.7rem;
    padding: 0;
    border-radius: 0;
  }
  
  .banner .infoProfile .dataProfile .otherDesc .desc {
    color: var(--cuarto);
  }

  .banner .infoProfile .dataProfile .otherDesc .contFrom {
    /* background: #c06565; */
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
  }

  .infoPrincipal {
    width: 100%;
    height: 5.5rem;
    display: inline-block;
    white-space: nowrap;
    overflow: scroll;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
  }

  .infoPrincipal::-webkit-scrollbar {
    display: none;
  }

  .infoPrincipal .itemBanner {
    display: inline-flex;
    vertical-align: middle;
    background: var(--terciario);
    min-width: 50%;
    height: 100%;
    margin-right: 1rem;
  }

  /*------------------- ESTILOS DEL INFORMATION GENERAL -------------------*/

  .infoGral {
    /* background: #c1f790; */
    width: 100%;
    height: fit-content;
    padding: 1rem 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    row-gap: 0;
  }

  /*------------------- ESTILOS DE DATOS GENERALES /RELEVANTES-------------------*/

  .infoGral .relevant {
    height: fit-content;
    margin: 0;
    padding: 0;
  }

  .contTitle {
    padding: 0 1rem;
  }

  .infoGral .relevant .contData {
    height: fit-content;
    padding: 0 1rem;
    display: inline-block;
    white-space: nowrap;
    overflow: scroll;
    padding: 0.5rem 1rem;
  }

  .dataGral {
    /* background: #c94646; */
    width: fit-content;
    height: 4.5rem;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 0;
    margin-bottom: 0;
    margin-right: 1rem;
    padding: 0 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 0 #ececec;
  }

  /*------------------- ESTILOS DE DATOS PREPARATORIA -------------------*/

  .infoGral .highSchool {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    /* background: #000; */
  }

  .infoGral .highSchool::-webkit-scrollbar {
    display: none;
  }

  .highSchool .school {
    width: 100%;
    height: 10rem;
  }

  .highSchool .ubication {
    display: flex;
    flex-direction: row;
  }

  .highSchool .date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .highSchool .date .average {
    width: 60%;
  }

  /*------------------- ESTILOS DEL ENTITY -------------------*/

  .infoGral .entity {
    grid-area: entity;
    /* background: #bad4fc; */
    height: fit-content;
    padding: 1rem;
  }

  /*------------------- ESTILOS DEL ADRESS -------------------*/

  .infoGral .address {
    grid-area: address;
    /* background: #000; */
    height: fit-content;
    padding: 1rem;
  }

  /*------------------- ESTILOS DE CARD LOCATION -------------------*/

  .contLocation {
    height: 13rem;
    padding: 1rem;
    box-shadow: 0 0 10px 0 #ececec;
  }

  .contLocation .contTitle .icon {
    font-size: 1.2rem;
    margin-right: 1rem;
  }

  .contLocation .contTitle .title {
    font-size: 1rem;
  }

  .contLocation .contText {
    padding: 1rem;
  }

  /*------------------- ESTILOS INFOECONOMIC -------------------*/

  .infoEconomic {
    width: 100%;
    height: fit-content;
    padding: 0;
  }

  .infoEconomic .dataEconomic {
    width: 100%;
    height: fit-content;
    display: inline-block;
    white-space: nowrap;
    overflow: scroll;
    padding: 0.5rem 1rem;
  }

  .infoEconomic .dataEconomic::-webkit-scrollbar {
    display: none;
  }

  .infoEconomic .dataEconomic .itemDesc {
    background: var(--terciario);
    width: 80%;
    display: inline-flex;
    flex-direction: column;
    padding: 1rem;
    box-shadow: 0 0 10px 0 #ececec;
    margin-right: 1rem;
  }

  /*------------------- ESTILOS DE CONTACTFILE -------------------*/

  .contFile {
    width: 100%;
    height: fit-content;
    padding: 0;
  }

  .contFile .viewFile {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
  }

  /*------------------- ESTILOS DATOS DE CONTCONTACT -------------------*/

  .contContact {
    height: fit-content;
    padding: 0;
    margin: 0;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    row-gap: 0;
  }

  .contContact .contTitle .title {
    color: var(--primario);
    font-weight: bold;
  }

  /*------------------- ESTILOS DATOS DE TUTOR -------------------*/

  .contContact .contTutor {
    width: 100%;
    height: fit-content;
    padding: 1rem 0;
  }

  .contContact .contTutor .contItems {
    width: 100%;
    height: fit-content;
    padding: 1rem;
    display: inline-block;
    white-space: nowrap;
    overflow: scroll;
  }

  .contContact .contTutor .contItems .tutor {
    background: var(--terciario);
    width: 100%;
    height: fit-content;
    display: inline-flex;
    margin-right: 1rem;
    margin-bottom: 0;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 0 #ececec;
  }

  /*------------------- ESTILOS DATOS DE CONTACT -------------------*/

  .contContact .contact {
    /* background: #f7d168; */
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    /* margin-bottom: 5rem; */
  }

  .contContact .contact .infoContact {
    /* background: var(--success); */
    width: 100%;
    height: 43vh;
    border-radius: 0;
    padding: 0 2rem 5rem 2rem;
    /* display: inline-block;
    white-space: nowrap;
    overflow: scroll; */
  }

  .contContact .contact .infoContact .item {
    width: 100%;
    display: inline-flex;
    margin-bottom: 0.5rem;
    padding: 0 1rem;
  }
}
