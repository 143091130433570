.link {
  /* background: var(--secundario); */
  height: 40px;
  color: var(--primario);
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.link:hover .iconLink {
  background: var(--primario);
  color: var(--terciario);
}

.link .iconLink {
  background: var(--terciario);
  color: var(--primario);
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.8rem;
  border-radius: 0.3rem;
  box-shadow: 8px 8px 10px 0 #0001;
  transition: all 0.5s ease;
}
