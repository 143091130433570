.card {
  background: var(--terciario);
  border-radius: 1rem;
}

.subject {
  /* background: #000; */
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid var(--border);
}

.subject .contImage {
  width: 3.5rem;
  height: 3.5rem;
}

.subject .contImage .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  border-radius: 1rem;
}

.subject .info {
  /* background: #d15959; */
  width: calc(100% - 3.5rem);
  height: 3.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 0.8rem;
}

.subject .info .name {
  color: var(--primario);
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: capitalize;
}

.subject .info .contTeacher {
  /* background: #d17d7d; */
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.subject .info .contTeacher .photo {
  background: #000;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  object-fit: cover;
}

.subject .info .teacher {
  color: var(--cuarto);
  font-size: 0.75rem;
  text-transform: capitalize;
}

.subject .info .teacher .icon {
  margin-right: 0.5rem;
}

.subjectData {
  /* background: #000; */
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
  padding: 1rem;
}

.subjectData .desc {
  /* background: #000; */
  color: var(--cuarto);
  font-size: 0.75rem;
}

.subjectData .desc .icon {
  font-size: 0.75rem;
  margin-right: 0.4rem;
}

.subjectData .students {
  /* background: #000; */
  display: flex;
  flex-direction: row;
}

.subjectData .students .photoStudent {
  /* background: #000; */
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--terciario);
}

.subjectData .students .second {
  transform: translateX(-0.5rem);
}

.subjectData .students .third {
  transform: translateX(-1rem);
}

.subjectData .students .count {
  background: var(--primario);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 2px solid var(--terciario);
  color: var(--terciario);
  font-size: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-1.5rem);
}
