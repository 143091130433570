.container {
  /* background: #f0c4c4; */
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  position: relative;
  /* overflow: scroll; */
}

.contTitle {
  /* background: #eb4646; */
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contTitle .title {
  color: var(--primario);
  font-size: 1.2rem;
  /* text-transform: capitalize; */
}

.contTitle .contActions {
  /* background: #e2f5b0; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  column-gap: 1rem;
}

.contTitle .contActions .actFilter {
  /* background: #7dd86b; */
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.contTitle .contActions .onlyPaidFilter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.contTitle .contActions .btnFilter {
  background: var(--terciario);
  color: var(--primario);
  width: 1.8rem;
  height: 1.8rem;
  font-size: 0.8rem;
  border: none;
  border-radius: 50%;
  box-shadow: 0 5px 10px 0 #ececec;
}

.contTitle .contActions .contSearch {
  /* background: #000; */
  width: 17rem;
  height: 40px;
}

/*----------------------- TABLE -----------------------*/

.container .contTable {
  /* background: #a4e935; */
  width: 100%;
  height: calc(100% - 5rem);
  overflow-y: scroll;
}

.container .contTable::-webkit-scrollbar {
  display: none;
}

.container .contTable .table {
  /* background: #ebe03e; */
  width: 100%;
  height: fit-content;
  border-collapse: separate;
  border-spacing: 0 1rem;
  margin: -1rem 0;
  padding: 0 0 7rem 0;
}

.container .table .headTable {
  background: var(--fondo);
  /* background: #a46bda; */
  width: 100%;
  height: 3rem;
  position: sticky;
  top: 0;
}

.container .table .headTable .contField .field {
  color: var(--primario);
  font-size: 0.9rem;
  text-align: center;
  font-weight: bold;
  position: sticky;
  top: 0;
}

.container .table .headTable .contField .field:nth-child(1) {
  text-align: left;
  padding: 0 1rem;
}

.container .table .bodyTable {
  background: #6b94da;
  width: 100%;
  height: calc(100% - 3rem);
}

.container .table .bodyTable .row .data {
  background: var(--terciario);
  height: 4rem;
  color: var(--cuarto);
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
}

.container .table .bodyTable .row .data:nth-child(1) {
  text-align: left;
  padding: 0 1rem;
  text-transform: capitalize;
  border-radius: 1rem 0 0 1rem;
}

.container .table .bodyTable .row .data:nth-last-child(1) {
  border-radius: 0 1rem 1rem 0;
}

.container .table .bodyTable .row .data:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .contButton {
  background: var(--fondo);
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
}

.contModal {
  width: 50vw;
  height: max-content;
  max-height: 70vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 1.5rem;
}

.overflowDataList {
  overflow: auto;
}

.infoDetailsStudent {
  display: grid;
  grid-template-columns: 25vw 25vw;
}

.stylesContainerButtonPrincipal {
  margin: 1rem;
}