.chart {
  /* background: #f16666; */
  width: 77%;
  height: 100%;
}

.summary {
  background: var(--fondo);
  width: 22%;
  height: 100%;
  padding: 1rem;
  border-radius: 1rem;
}

.summary .contTitle {
  /* background: #d83939; */
  width: 100%;
  height: 15%;
}

.summary .contTitle .title {
  color: var(--primario);
  font-size: 0.9rem;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.summary .contData {
  /* background: #d83939; */
  width: 100%;
  height: 85%;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: .8rem;
}

.item {
  /* background: #000; */
  display: flex;
  align-items: center;
  flex-direction: row;
}

.item .iconItem {
  background: var(--terciario);
  width: 3rem;
  height: 3rem;
  border-radius: .8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item .iconItem .primary {
  color: var(--danger);
}

.item .iconItem .secondary {
  color: var(--send);
}

.item .iconItem .tertiary {
  color: var(--success);
}

.item .contData {
  /* background: #f08787; */
  width: calc(100% - 3rem);
  height: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 1rem;
  gap: 0;
}

.item .contData .txtdata {
  color: var(--primario);
  font-size: .9rem;
  font-weight: bold;
}

.item .contData .desc {
  color: var(--cuarto);
  font-size: .7rem;
}