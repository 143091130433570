.container {
  background: var(--fondo);
  width: 100%;
  height: 100vh;
}

/*------------------ Header ------------------*/

.header {
  background: var(--primario);
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: row;
}

.header .contImage {
  /* background: #000; */
  width: 5vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .contImage .image {
  width: 2.5rem;
  height: 2.5rem;
}

.header .headerTitle {
  /* background: #157ff8; */
  width: calc(100% - 5vw);
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem;
}

.header .headerTitle .title {
  color: var(--terciario);
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: capitalize;
}

.header .headerTitle .desc {
  color: var(--cuarto);
  font-size: 0.8rem;
  text-transform: capitalize;
}

/*------------------ Contenido de la politica de privacidad ------------------*/

.contText {
  width: 100%;
  height: 90vh;
  padding: 5rem;
  overflow: scroll;
}

.contText::-webkit-scrollbar {
  display: none;
}

.contText .contTitle {
  /* background: #eba7a7; */
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border);
  margin-bottom: 2rem;
}

.contText .title {
  color: var(--primario);
  font-size: 2rem;
}

.text {
  color: var(--primario);
  font-size: 0.9rem;
  margin-bottom: 1rem;
  text-align: justify;
  animation: text 1s forwards;
}

.text span {
  color: var(--secundario);
  font-weight: bold;
}

@keyframes text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 858px) {

  .header .contImage {
    /* background: #fdc495; */
    width: 5rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header .contImage .image {
    width: 2.5rem;
    height: 2.5rem;
  }

  .header .headerTitle {
    width: calc(100% - 5rem);
    padding: 0;
  }

  /* .contText {
    padding: 5rem;
  } */
}

@media screen and (max-width: 620px) {
  /* .container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  .header .contImage {
    width: 4rem;
  }

  .header .contImage .image {
    width: 2rem;
    height: 2rem;
  }

  .header .headerTitle {
    width: calc(100% - 4rem);
  }

  .header .headerTitle .title {
    font-size: 0.8rem;
  }

  .header .headerTitle .desc {
    font-size: 0.7rem;
  }

  .contText {
    /* background: #f5adad; */
    padding: 2rem;
  }

  .contText .contTitle .title {
    font-size: 1.5rem;
  }

}
