.contInput {
  width: fit-content;
  height: fit-content;
  line-height: 0;
  margin: 0;
}

.contInput .input {
  opacity: 0;
  height: 0;
  width: 0;
}

.checkmark {
  background: var(--terciario);
  height: 1.2rem;
  width: 1.2rem;
  display: block;
  border-radius: 0.3rem;
  border: 1px solid var(--cuarto);
  transition: all 0.5s ease;
}

.contInput .input:checked ~ .checkmark {
  background: var(--primario);
  border: 1px solid var(--primario);
}

.checkmark .icon {
	color: var(--terciario);
	font-size: .7rem;
	opacity: 0;
}

.contInput input:checked ~ .checkmark .icon {
  opacity: 1;
}