.container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.container::-webkit-scrollbar {
  display: none;
}

.title {
  color: var(--primario);
  font-size: 1.2rem;
  /* margin-bottom: 1rem; */
}

.header {
  /* background: #dbaae7; */
  width: 100%;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
}

.contTable {
  /* background: #f3adad; */
  width: 100%;
  height: calc(100% - 3.5rem);
}

.modalAdd {
  /* background: #000; */
  width: 25rem;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formInput {
  /* background: #d88080; */
  width: 100%;
  /* height: 10rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}

.formInput .inputalt {
  /* background: #99d880; */
  width: 100%;
}

.editModal {
  /* background: #000; */
  width: 25rem;
  height: fit-content;
  padding: 2rem 1rem;
}

.editModal .formEdit {
  /* background: #d86969; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}

.editSpecialties {
  width: 50rem;
  height: fit-content;
  padding: 2rem 1rem;
}

.editSpecialties .formEditSpecialties {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}

.editSpecialties .inputs {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  justify-items: center;
  align-items: center;
}
