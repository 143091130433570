.login {
  /* background: #5d5add; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1.5rem;
  position: relative;
}

.title {
  color: var(--cuarto);
  font-size: 0.9rem;
}

/*----------------- BOTONES DE OPCIONES PARA INICIO DE SESIÓN -----------------*/

.login .btnOpc {
  background: var(--terciario);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13rem;
  height: 2.8rem;
  font-size: 0.9rem;
  border: none;
  border-radius: 0.3rem;
  box-shadow: 0 5px 10px 0 #e8e8e8;
}

.login .btnAspirant {
  background: var(--primario);
  color: var(--terciario);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login .btnOpc .img {
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
}

.login .btnOpc .icon {
  font-size: 1rem;
  margin-right: 1rem;
}
