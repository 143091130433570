.container {
  width: 100%;
  height: 40px;
  border-radius: 8px;
}

.container > td > div {
  background: linear-gradient(90deg, #ebebeb, #c8c7c7);
  background-size: 400% 400%;
  width: 90%;
  height: 60%;
  border-radius: 4px;
  animation: loadingAnimation 3s ease-in-out infinite;
}

.id,
.status,
.birthDate,
.genre {
  margin: 0 auto;
}

@keyframes loadingAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  1000% {
    background-position: 0% 50%;
  }
}
