.container {
  /* background: #000; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}

.container .subjects {
  /* background: #ee4040; */
  width: calc(100% - 23rem);
  height: 100%;
  padding: 2rem 2rem 0 2rem;
  overflow: scroll;
}

.container .subjects::-webkit-scrollbar {
  display: none;
}

.container .contSchedule {
  width: 23rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: width 0.5s ease-out;
}

.container .openSchedule {
  background: #04040404;
  width: 100%;
  border-radius: 2rem 0 0 0;
  backdrop-filter: blur(5px);
  transition: width 0.5s ease-in;
}

.container .contSchedule .schedule {
  /* background: #78e66e; */
  width: 100%;
  height: 100%;
  transition: width 0.5s ease-out;
}

.container .openSchedule .schedule {
  /* background: #e66e6e; */
  width: 80%;
  height: 100%;
  transition: width 0.5s ease-in;
}

.container .schedule .headSchedule {
  background: var(--terciario);
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
}

.container .schedule .headSchedule .btnFullSchedule {
  background: var(--terciario);
  width: 2.5rem;
  height: 100%;
  border: none;
  color: var(--cuarto);
  transition: all 0.5s ease;
}

.container .schedule .headSchedule .btnFullSchedule:hover {
  background: #f3f3f3;
  color: var(--send);
}

.container .schedule .headSchedule .btnFullSchedule .iconClose {
  transform: rotateZ(0deg);
  transition: all 0.5s ease;
}

.container .schedule .headSchedule .btnFullSchedule .iconOpen {
  transform: rotateZ(180deg);
  transition: all 0.5s ease;
}

.container .schedule .headSchedule .contTitle {
  width: calc(100% - 2rem);
  height: 100%;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.container .schedule .headSchedule .contTitle .title {
  color: var(--primario);
  font-size: 1.2rem;
}

.container .schedule .headSchedule .contTitle .desc {
  color: var(--cuarto);
  font-size: 0.9rem;
  line-height: 0.9rem;
}

.container .schedule .tableSchedule {
  background: var(--terciario);
  width: 100%;
  height: calc(100% - 4rem);
}
