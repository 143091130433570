.header {
  /* background: #e97d7d; */
  width: 100%;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.header .title {
  color: var(--primario);
  font-size: 1.2rem;
  /* margin-bottom: 1rem; */
}

.header .action {
  /* background: #000; */
  display: flex;
  gap: 1rem;
}

.header .action .search {
  /* background: #000; */
  width: 17rem;
  height: 40px;
}

.contCards {
  /* background: #a7c044; */
  width: 100%;
  height: calc(100% - 3.5rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8rem;
}

.contCards .contButtons {
  display: flex;
  gap: 2rem;
}

/* .FindCredentials_Search {
  text-align: center;
  width: 400px;
  margin: 0 auto;
}

.FindCredentials_Error {
  margin: 0 auto;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FindCredentials_Error > i::before {
  font-size: 80px;
  color: var(--danger);
}

.FindCredentials_Error > p {
  font-size: 16pt;
  color: var(--danger);
}

.FindCredentials_Search .FindCredentials_Input {
  width: inherit;
  height: 45px;
  margin-top: 16px;
}

.FindCredentials_Aspirant * {
  margin: 0 !important;
  box-sizing: border-box;
}

.FindCredentials_Aspirant button {
  width: 100%;
  font-size: 12pt;
}

.FindCredentials_Aspirant {
  background-color: var(--lighter);
  border-radius: 8px;
  display: grid;
  width: 550px;
  height: 224px;
  grid-template-columns: auto 1fr;
  gap: 20px;
  margin: 0 auto;
  margin-top: 20px;
  box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1);
  padding: 12px;
}

.FindCredentials_Aspirant .FindCredentials_ImageContainer {
  height: 200px;
}

.FindCredentials_Aspirant .FindCredentials_ImageContainer img {
  height: 100%;
}

.FindCredentials_Aspirant .FindCredentials_AspirantData {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.FindCredentials_Aspirant .FindCredentials_curp,
.FindCredentials_matricula,
.FindCredentials_Aspirant .FindCredentials_career {
  font-size: 10pt;
  text-transform: capitalize;
}

.FindCredentials_Aspirant .FindCredentials_options {
  display: flex;
  gap: 12px;
} */
