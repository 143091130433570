.container {
  /* background: #000; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.container .contPrincipal {
  /* background: #ce3333; */
  width: calc(100% - 18rem);
  overflow: scroll;
  gap: 1rem;
}

.contPrincipal::-webkit-scrollbar {
  display: none;
}

.title {
  color: var(--primario);
  font-size: 1rem;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

/*------------------------------ WELCOME ------------------------------*/

.contPrincipal .welcome {
  /* background: var(--terciario); */
  width: 100%;
  margin-bottom: 1.3rem;
}

.contPrincipal .welcome .greet {
  color: var(--primario);
  font-size: 1.2rem;
}

.contPrincipal .welcome .greet .name {
  color: var(--secundario);
}

.contPrincipal .welcome .desc {
  color: var(--cuarto);
  font-size: 0.9rem;
}

/*------------------------------ CAREER ------------------------------*/

.contPrincipal .career {
  background: var(--terciario);
  width: 100%;
  height: 17rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 1.3rem;
}

/*------------------------------ RELEVANT DATAS ------------------------------*/

.contPrincipal .relevantData {
  /* background: #ee5555; */
  width: 100%;
  height: 25rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "groups academicArea"
    "groups academicArea"
    "groups classroom";
  gap: 1rem;
  margin-bottom: 1.3rem;
}

.contPrincipal .relevantData .cardRelevant {
  background: var(--terciario);
  border-radius: 1rem;
  padding: 1rem;
}

.contPrincipal .relevantData .cardRelevant .headerCard {
  /* background: #000; */
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contPrincipal .relevantData .cardRelevant .headerCard .title {
  color: var(--primario);
  font-size: 1rem;
}

.contPrincipal .relevantData .cardRelevant .headerCard .btn {
  background: #bfd3ff;
  border: none;
  border-radius: 0.5rem;
  color: var(--primario);
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem;
}

.contPrincipal .relevantData .groups {
  grid-area: groups;
  /* background: #000; */
}

.contPrincipal .relevantData .groups .contGroups {
  /* background: #519404; */
  width: 100%;
  height: calc(100% - 3rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  /* gap: 1rem; */
  padding-top: 1rem;
}

.contGroups .itemGroup {
  /* background: #d14646; */
  width: 100%;
  /* height: 3rem; */
  display: flex;
  flex-direction: row;
  gap: 1rem;
  /* border: 1px solid; */
}

.contGroups .itemGroup .group {
  background: var(--terciario);
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px 0 #e1e1e1;
}

.contGroups .itemGroup .group .text {
  color: var(--primario);
  font-size: 0.8rem;
}

.contGroups .itemGroup .dataGroup {
  /* background: #da4646; */
  width: calc(70% - 2rem);
  overflow: hidden;
}

.contGroups .itemGroup .dataGroup .subject {
  color: var(--cuarto);
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 0.9rem;
}

.contGroups .itemGroup .dataGroup .period {
  color: var(--cuarto);
  font-size: 0.8rem;
  text-transform: capitalize;
  /* line-height: 1rem; */
}

.contGroups .itemGroup .progress {
  width: 30%;
  height: 100%;
  /* display: flex;
  justify-content: center;
  flex-direction: column; */
  text-align: right;
}

.contGroups .itemGroup .progress .text {
  color: var(--cuarto);
  font-size: 0.7rem;
  margin-bottom: .4rem;
}

.contPrincipal .relevantData .academicArea {
  grid-area: academicArea;
  /* background: #c715eb; */
}

.contPrincipal .relevantData .academicArea .contAcademicArea {
  /* background: #000; */
  width: 100%;
  height: calc(100% - 3rem);
  height: 8rem;
}

.contPrincipal .relevantData .classroom {
  grid-area: classroom;
  /* background: #76e62c; */
}

.contPrincipal .relevantData .classroom .contClassroom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
}

.contClassroom .itemClassroom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.contClassroom .itemClassroom .contPhoto {
  /* background: #000; */
  width: 2rem;
  height: 2rem;
  position: relative;
}

.contClassroom .itemClassroom .contPhoto .photo {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  object-fit: cover;
}

.contClassroom .itemClassroom .contPhoto .status {
  background: var(--danger);
  width: 0.8rem;
  height: 0.8rem;
  border: 3px solid var(--terciario);
  border-radius: 50%;
  position: absolute;
  top: -0.3rem;
  right: -0.3rem;
}

.contClassroom .itemClassroom .contPhoto .correctly {
  background: var(--success);
}

.contClassroom .itemClassroom .name {
  color: var(--cuarto);
  font-size: 0.8rem;
}

/*------------------------------ CONTENT SECONDARY ------------------------------*/

.container .contSchoolPeriods {
  /* background: #ca3f3f; */
  width: 18rem;
}
