.contModal {
  background: #00000070;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contModal .modal {
  background: var(--fondo);
  /* background: #d19595; */
  width: fit-content;
  height: fit-content;
  border-radius: 1rem;
  box-shadow: 0 0 15px 10px #6e6e6e65;
  padding: 0 1rem;
	/* overflow: hidden; */
	animation: modal 1s forwards;
}

.contModal .modal::-webkit-scrollbar {
	display: none;
}

@keyframes modal {
  0% {
    transform: translateY(20px);
		opacity: 0;
  }
  100% {
    transform: translateY(0px);
		opacity: 1;
  }
}

.contHeader {
  /* background: #f8b0b0; */
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
  /* padding: 0 1rem; */
}

.contHeader .titleHeader {
  color: var(--primario);
  font-size: 1rem;
}

.contHeader .btnClose {
	background: transparent;
	border: none;
  color: var(--primario);
  font-size: 1rem;
}

.contHeader .btnClose:hover {
  color: var(--danger);
}

.contBody {
  /* background: #f8b0b0; */
  width: fit-content;
  height: fit-content;
  /* overflow: scroll; */
  padding: 0 2rem;
}

/* .contBody::-webkit-scrollbar {
  display: none;
} */
