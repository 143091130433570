.contCard {
  width: 25rem;
  height: 15rem;
  perspective: 1000px;
}

.contCard .cardInner {
  /* background: #ee1616; */
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  border-radius: 1rem;
  /* animation: spin 7s infinite; */
}

.contCard .spin {
  transform: rotate3d(5, 5, 1, 180deg);
}

.contCard .spinLoading {
  animation: spin 7s infinite;
}

.contCard .card {
  background: var(--terciario);
  border-radius: 1rem;
  box-shadow: 25px 39px 81px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 25px 39px 81px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 25px 39px 81px 2px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.contCard .cardInner .cardFront,
.contCard .cardInner .cardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.contCard .cardFront {
  overflow: hidden;
  /* transform: rotate3d(5, 5, 1, 0deg); */
  /* animation: spin 4s infinite; */
}

.contCard .cardFront .head {
  width: 100%;
  height: 3.75rem;
  display: flex;
  flex-direction: row;
}

.contCard .cardFront .designPattern {
  width: 35%;
  height: 100%;
}

.contCard .cardFront .school {
  /* background: #d2f8f6; */
  width: 65%;
  height: 100%;
  padding-right: 1rem;
}

.contCard .cardFront .information {
  /* background: #fff2df; */
  width: 100%;
  height: calc(100% - 3.75rem);
  display: flex;
  flex-direction: row;
}

.contCard .cardFront .information .contPhoto {
  /* background: #000; */
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contCard .cardFront .information .contPhoto .img {
  width: 6rem;
  height: 8rem;
  border-radius: 0.3rem;
}

.contCard .cardFront .information .student {
  /* background: #d57777; */
  width: 65%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;
}

.contCard .cardFront .information .contWarning {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.contCard .cardFront .information .contWarning .text {
  color: var(--primario);
  font-size: 1rem;
}

.contCard .cardFront .information .contWarning .textDanger {
  color: var(--danger);
  font-size: 1rem;
}

.contCard .cardFront .information .student .contData .title {
  color: var(--cuarto);
  font-size: 0.75rem;
  line-height: 0.8rem;
}

.contCard .cardFront .information .student .contData .desc {
  color: var(--primario);
  font-size: 0.9rem;
}

.contCard
  .cardFront
  .information
  .student
  .contData:nth-child(2)
  .desc::first-letter {
  /* font-size: 0.8rem; */
  text-transform: uppercase;
}

.contCard .cardBack {
  transform: rotate3d(5, 5, 1, 180deg);
  /* background: #faf3bd; */
  display: flex;
  flex-direction: row;
  overflow: hidden;
  /* animation: spin2 7s infinite; */
}

.contCard .loadingBack {
  animation: spin2 7s infinite;
}


@keyframes spin {
  0% {
    transform: rotate3d(5, 5, 1, 180deg);
  }
  50% {
    transform: rotate3d(5, 5, 1, 0deg);
  }
  100% {
    transform: rotate3d(5, 5, 1, 180deg);
  }
}

@keyframes spin2 {
  0% {
    transform: rotate3d(5, 5, 1, 180deg);
  }
  50% {
    transform: rotate3d(5, 5, 1, 180deg);
  }
  100% {
    transform: rotate3d(5, 5, 1, 180deg);
  }
}

.contCard .cardBack .designPattern {
  width: 35%;
  height: 100%;
}

.contCard .cardBack .infoSchool {
  /* background: #e5e5f7; */
  width: 65%;
  height: 100%;
  padding: 1rem;
}

.contCard .school {
  /* background: #f18585; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  gap: 0.3rem;
}

.contCard .school .contName {
  /* background: #c9f7f7; */
  text-align: right;
}

.contCard .school .contName .name {
  color: var(--primario);
  font-size: 0.7rem;
  line-height: 0.6rem;
}

.contCard .school .contName .desc {
  color: var(--cuarto);
  font-size: 0.6rem;
}

.contCard .school .logo {
  /* background: #f2e290; */
  width: 2rem;
  height: 2rem;
}

.pattern {
  background-color: var(--terciario);
  background-image: linear-gradient(
      135deg,
      var(--terciario) 25%,
      transparent 25%
    ),
    linear-gradient(225deg, var(--terciario) 25%, transparent 25%),
    linear-gradient(45deg, var(--terciario) 25%, transparent 25%),
    linear-gradient(315deg, var(--terciario) 25%, var(--primario) 25%);
  background-position: 10px 0, 10px 0, 0 0, 0 0;
  background-size: 20px 20px;
  background-repeat: repeat;
}

/* .pattern {
  background-color: #ffffff;
  opacity: 1;
  background: radial-gradient(
      circle,
      transparent 20%,
      #ffffff 20%,
      #ffffff 80%,
      transparent 80%,
      transparent
    ),
    radial-gradient(
        circle,
        transparent 20%,
        #ffffff 20%,
        #ffffff 80%,
        transparent 80%,
        transparent
      )
      25px 25px,
    linear-gradient(#1b396a 2px, transparent 2px) 0 -1px,
    linear-gradient(90deg, #1b396a 2px, #ffffff 2px) -1px 0;
  background-size: 50px 50px, 50px 50px, 25px 25px, 25px 25px;
} */
