.container {
  /* background: #000; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.container .contItems {
  /* background: #7cda7c; */
  width: 75%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "welcome welcome welcome"
    "aspirantInfo aspirantInfo register"
    "info info register";
  grid-gap: 1rem;
  padding: 0 1rem 1rem 0;
}

.contItems .welcome {
  grid-area: welcome;
  background: var(--terciario);
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  padding: 0 2rem;
}

.contItems .register {
  background: var(--primario);
  grid-area: register;
  /* margin-bottom: 1.5rem; */
  border-radius: 1rem;
  cursor: pointer;
  /* display: none; */
  /* background: #ec2a2a; */
}

.contItems .register .contText {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.contItems .register .contText .title {
  color: var(--terciario);
  font-size: .9rem;
}

.contItems .register .contText .desc {
  color: var(--cuarto);
  font-size: .8rem;
  margin-top: -1.5rem;
}

.contItems .register .contTitle {
  /* background: #6cbb4d; */
  width: 100%;
  height: 13%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.contItems .register .contTitle .title {
  color: var(--terciario);
  font-size: 1rem;
}

.contItems .register .contTitle .btnForm {
  background: var(--fondo);
  width: 1.5rem;
  height: 1.5rem;
  color: var(--primario);
  font-size: 0.8rem;
  border-radius: 50%;
  border: none;
}

.contItems .register .contGraph {
  /* background: #4175b8; */
  width: 100%;
  height: 87%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1rem;
}

.contItems .aspirantInfo {
  /* background: #c7c95a; */
  grid-area: aspirantInfo;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-template-areas: "contVideo contVideo status";
  grid-gap: 1rem;
}

.contItems .aspirantInfo .status {
  background: var(--terciario);
  grid-area: status;
  border-radius: 1rem;
  padding: 1rem 1rem 0 1rem;
  /* display: none; */
}

.contItems .aspirantInfo .status .headStatus {
  /* background: #000; */
  display: flex;
  align-items: center;
  flex-direction: row;
  /* column-gap: 0.8rem; */
}

.contItems .aspirantInfo .status .headStatus .contIcon {
  /* background: #16bd3f46; */
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}

.contItems .aspirantInfo .status .headStatus .contTitle {
  /* background: #b65454; */
  width: calc(100% - 2.5rem);
  height: 100%;
  padding-left: .5rem;
}

.contItems .aspirantInfo .status .headStatus .title {
  color: var(--cuarto);
  font-size: 0.7rem;
}

.contItems .aspirantInfo .status .headStatus .desc {
  color: var(--primario);
  font-size: 0.7rem;
  /* font-weight: bold; */
  text-transform: uppercase;
  margin-top: -5px;
}

.contItems .aspirantInfo .status .contIcon .icon {
  /* color: var(--success); */
  font-size: 1.2rem;
}

.contItems .aspirantInfo .status .infoStatus {
  /* background: #bd9616; */
  width: 100%;
  height: calc(100% - 2.5rem);
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-right: 0.5rem; */
}

.contItems .aspirantInfo .status .infoStatus .desc {
  color: var(--primario);
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1.1rem;
  text-align: center;
}

.contItems .aspirantInfo .status .infoStatus .desc::first-letter {
  text-transform: uppercase;
}

.contItems .aspirantInfo .contVideo {
  background: var(--terciario);
  grid-area: contVideo;
  border-radius: 1rem;
  padding: 1rem;
}

.contItems .aspirantInfo .contVideo .video {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 1rem;
}

.contItems .info {
  /* background: #6cbb4d; */
  grid-area: info;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  /* display: none; */
  /* padding-bottom: 1.5rem; */
}

.contItems .info .contact {
  /* background: #4db4bb; */
  grid-column: 1/4;
}

.contItems .info .contact .contTitle {
  /* background: #e05d5d; */
  width: 100%;
  height: 25%;
}

.contItems .info .contact .contTitle .title {
  color: var(--primario);
  font-size: 1rem;
}

.contItems .info .contact .contInfo {
  /* background: #e05d5d; */
  width: 100%;
  height: 75%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.contItems .info .contact .contInfo .address {
  /* background: #000; */
  grid-column: 1/-1;
}

.contItems .info .contact .contInfo .itemContact {
  /* background: var(--terciario); */
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 0.5rem;
}

.contItems .info .contact .contInfo .itemContact .title {
  color: var(--cuarto);
  font-size: 0.7rem;
}

.contItems .info .contact .contInfo .itemContact .desc {
  color: var(--primario);
  font-size: 0.85rem;
}

.contItems .info .social {
  /* background: #000; */
  grid-column: 4/5;
}

.contItems .info .social .contTitle {
  /* background: #e05d5d; */
  width: 100%;
  height: 25%;
}

.contItems .info .social .contTitle .title {
  color: var(--primario);
  font-size: 1rem;
}

.contItems .info .social .contItem {
  /* background: #e05d5d; */
  width: 100%;
  height: 75%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1rem;
}

.contItems .info .social .contItem .itemSocial {
  background: var(--terciario);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.contItems .info .social .contItem .itemSocial .icon {
  color: var(--secundario);
  font-size: 1.3rem;
}

.contItems .contact .contItems {
  /* background: #5d75e0; */
  width: 100%;
  height: 70%;
}

/*------------------------ NOTIFICACIONES ------------------------*/

.notes {
  /* background: #245eb6; */
  /* grid-area: notes; */
  width: 25%;
  height: 100%;
  overflow: hidden;
}

.notes .headerNote {
  /* background: #000; */
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: space-between;
}

.notes .headerNote .title {
  color: var(--primario);
  font-size: 1rem;
}

.notes .headerNote .iconNoti {
  /* background: #000; */
  width: 3rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  position: relative;
}

.notes .headerNote .iconNoti .icon {
  color: var(--cuarto);
  font-size: 1.2rem;
}

.notes .headerNote .iconNoti .count {
  background: var(--danger);
  width: 0.9rem;
  height: 0.9rem;
  color: var(--terciario);
  font-size: 0.6rem;
  font-weight: bold;
  position: absolute;
  top: 0.25rem;
  right: 0.55rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.notes .cardsNote {
  /* background: #f7a2a2; */
  width: 100%;
  height: calc(100% - 2rem);
  padding-top: 1rem;
  overflow-y: scroll;
}

.notes .cardsNote::-webkit-scrollbar {
  display: none;
}

/*------------------------- ESTADO DEL ASPIRANTE -------------------------*/

.aspirantInfo .status .aceptado {
  background: #d3fcd8;
  color: var(--success);
  box-shadow: 0 0 20px 0px #d3fcd8;
}

.aspirantInfo .status .aspirante {
  background: #d3fcd8;
  color: var(--success);
  box-shadow: 0 0 20px 0px #d3fcd8;
}

.aspirantInfo .status .rechazado {
  background: #fcd3d3;
  color: var(--danger);
  box-shadow: 0 0 20px 0px #fcd3d3;
}

.aspirantInfo .status .registrado {
  background: var(--register);
  color: #f7f7f7;
  box-shadow: 0 0 20px 0px var(--register);
}

.aspirantInfo .status .formularios {
  background: #f5d32a;
  color: var(--form);
  box-shadow: 0 0 20px 0px #f5d32a;
}

.aspirantInfo .status .enviado {
  background: #8faffa;
  color: var(--send);
  box-shadow: 0 0 20px 0px #8faffa;
}

.aspirantInfo .status .revisado {
  background: #ffe4cf;
  color: var(--secundario);
  box-shadow: 0 0 20px 0px #ffe4cf;
}

.aspirantInfo .status .observaciones {
  background: #ceb6ff;
  color: var(--observaciones);
  box-shadow: 0 0 20px 0px #ceb6ff;
}

.aspirantInfo .status .corregido {
  background: #ffa7ff;
  color: var(--primario);
}

.aspirantInfo .status .pagar {
  background: #ffe4cf;
  color: var(--secundario);
}

.aspirantInfo .status .pagado {
  background: #01b6a4;
  color: var(--terciario);
}

@media screen and (max-width: 858px) {
  .container .contItems {
    /* background: #7cda7c; */
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas:
      "welcome welcome welcome"
      "aspirantInfo aspirantInfo register"
      "info info register";
    grid-gap: 1rem;
    padding: 1rem 1rem 1rem 0;
  }
/* 
  .container::-webkit-scrollbar {
    display: none;
  } */

  .contItems .welcome {
    column-gap: 1rem;
    padding: 0 1rem;
    /* display: none; */
  }

  .contItems .register {
    margin: 0;
  }

  .contItems .aspirantInfo {
    display: flex;
    flex-direction: row-reverse;
  }

  .contItems .aspirantInfo .status {
    background: var(--terciario);
    width: 50%;
  }

  .contItems .aspirantInfo .status .headStatus .title {
    font-size: 1rem;
  }

  .contItems .aspirantInfo .contVideo {
    background: var(--terciario);
    width: 50%;
  }

  .notes {
    /* display: block; */
    background: var(--primario);
    width: 100%;
    height: calc(100vh - 4rem);
    position: fixed;
    top: 4rem;
    left: 0;
    padding: 1rem 1rem 0 1rem;
    transition: all 1s ease;
    border-radius: 0;
  }

  .notes .headerNote .title {
    color: var(--terciario);
  }

  .noNotes {
    height: 0;
    position: fixed;
    top: -100%;
    left: 0;
    border-radius: 0 0 1rem 1rem;
  }
  .notes .headerNote .iconNoti {
    display: none;
  }
}

@media screen and (max-width: 620px) {

  .container {
    padding: 1rem 0;
  }

  .container .contItems {
    /* background: #000; */
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    position: relative;
  }

  .container .contItems .welcome {
    /* background: #075664; */
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    row-gap: 1rem;
  }

  .welcome .contDesc {
    width: 100%;
    height: fit-content;
    padding: 0;
  }

  .welcome .contDesc .title {
    font-size: 0.9rem;
  }

  .welcome .contDesc .desc {
    font-size: 0.8rem;
  }

  .welcome .contImg {
    width: 100%;
    height: 6rem;
  }

  .container .contItems .register {
    width: 100%;
    height: fit-content;
  }

  .container .contItems .register .contTitle {
    height: 4rem;
  }

  .container .contItems .register .contGraph {
    padding: 1rem;
  }

  .container .contItems .aspirantInfo {
    /* background: #c95151; */
    width: 100%;
    height: fit-content;
    display: inline-block;
    white-space: nowrap;
    overflow: scroll;
  }

  .container .contItems .aspirantInfo::-webkit-scrollbar {
    display: none;
  }

  .container .contItems .aspirantInfo .status {
    width: 13rem;
    height: fit-content;
    display: inline-flex;
    flex-direction: column;
    vertical-align: middle;
    margin-right: 1rem;
  }

  .container .contItems .aspirantInfo .status .headStatus {
    width: 100%;
    height: 2.5rem;
  }

  .container .contItems .aspirantInfo .status .contIcon .icon {
    font-size: 1.2rem;
  }

  .container .contItems .aspirantInfo .status .infoStatus {
    width: 100%;
    height: 10rem;
    white-space: normal;
  }

  .container .contItems .aspirantInfo .contVideo {
    width: 100%;
    height: fit-content;
    display: inline-flex;
    vertical-align: middle;
  }

  .container .contItems .aspirantInfo .contVideo .video {
    width: 100%;
    height: 12.5rem;
  }

  .container .contItems .info {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
  }

  .container .contItems .info .contact {
    width: 100%;
    height: fit-content;
  }

  .container .contItems .info .contact .contTitle {
    width: 100%;
    height: 2.5rem;
  }

  .container .contItems .info .contact .contTitle .title {
    color: var(--primario);
    font-size: 1rem;
  }

  .container .contItems .info .contact .contInfo {
    width: 100%;
    height: fit-content;
  }

  .container .contItems .info .social {
    /* background: #000; */
    width: 100%;
    height: fit-content;
  }

  .container .contItems .info .social .contTitle {
    /* background: #e05d5d; */
    width: 100%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* display: none; */
  }

  .container .contItems .info .social .contTitle .title {
    color: var(--cuarto);
    font-size: 0.8rem;
  }

  .container .contItems .info .social .contItem {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container .contItems .info .social .contItem .itemSocial {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }

  .container .contItems .info .social .contItem .itemSocial .icon {
    font-size: 1rem;
  }

  .noteNotis {
    /* display: none; */
    background: var(--primario);
    width: 100%;
    height: calc(100vh - 4rem);
    position: fixed;
    top: 4rem;
    left: 0;
    padding: 1rem 1rem 0 1rem;
    transition: all 0.5s ease;
  }

  .noteNotis .headerNote .title {
    color: var(--terciario);
  }

  .noNotes {
    height: 0;
    transition: all 1s ease;
  }
  .notes .headerNote .iconNoti {
    display: none;
  }
}
