.card {
  background: var(--terciario);
  height: 17rem;
  padding: 1rem;
  border-radius: 1rem;
}

.card .contMap {
  background: #c8d8ff;
  width: 100%;
  height: 60%;
  border-radius: 1rem;
  overflow: hidden;
}

.card .classroom {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
}

.card .classroom .contInfo {
  /* background: #fffcc8; */
  width: calc(100% - 2rem);
  height: 100%;
}

.card .classroom .contInfo .name {
  color: var(--primario);
  font-size: 1rem;
  font-weight: bold;
}

.card .classroom .contInfo .desc {
  color: var(--cuarto);
  font-size: 0.75rem;
}

.card .classroom .contInfo .desc:nth-child(3) {
  text-transform: capitalize;
}

.card .classroom .btn {
  background: var(--send);
  color: var(--terciario);
  width: 1.8rem;
  height: 1.8rem;
  font-size: 0.8rem;
  border: 1px solid var(--send);
  border-radius: 0.5rem;
  transition: all .5s ease;
}

.card .classroom .btn:hover {
    background: transparent;
    color: var(--send);
}
