.container {
  background: var(--primario);
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: row;
}

.contBtnMenu {
  display: none;
}

.contImage {
  /* background: #fdc495; */
  width: 5vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contImage .image {
  width: 2.5rem;
  height: 2.5rem;
}

.contTitle {
  /* background: #157ff8; */
  width: calc((100% - 5vw) / 2);
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 2rem;
}

.contPerfil {
  /* background: #b2f7bd; */
  width: calc((100% - 5vw) / 2);
  height: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
}

.contPerfil .namePerfil {
  /* background: #f5d1c6; */
  width: calc(100% - 2.5rem);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  padding-right: 1rem;
}

.title {
  color: var(--terciario);
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: capitalize;
}

.desc {
  color: var(--cuarto);
  font-size: 0.8rem;
  text-transform: capitalize;
}

.contPerfil .imageProfile {
  /* background: #d46802; */
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contPerfil .imageProfile .imgProfile {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1rem;
  padding: 2px;
  border: 1px solid var(--cuarto);
  cursor: pointer;
  object-fit: cover;
}

.imageProfile .contIcon {
  background: var(--fondo);
  width: 2.5rem;
  height: 2.5rem;
  /* position: absolute; */
  /* top: -3rem; */
  border-radius: 1rem;
  /* border-radius: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.imageProfile .contIcon .icon {
  /* background: #000; */
  color: var(--cuarto);
  font-size: 2rem;
  position: absolute;
  bottom: 0;
  line-height: 0;
  cursor: pointer;
}

.notification {
  display: none;
}

@media screen and (max-width: 858px) {
  .container {
    height: fit-content;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .contBtnMenu {
    /* background: #e65f5f; */
    width: 5rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contImage {
    /* background: #fdc495; */
    width: 5rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contImage .image {
    width: 2.5rem;
    height: 2.5rem;
  }

  .contTitle {
    /* background: #157ff8; */
    width: calc(100% - ((5rem * 3) + 3rem));
    height: 4rem;
    padding: 0;
  }

  .contPerfil {
    /* background: #c7f815; */
    width: 5rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem;
  }

  .contPerfil .namePerfil {
    display: none;
  }

  .notification {
    /* background: #000; */
    width: 3rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .notification .icon {
    color: var(--cuarto);
    font-size: 1.4rem;
  }

  .notification .count {
    background: var(--danger);
    width: .9rem;
    height: .9rem;
    color: var(--terciario);
    font-size: .6rem;
    font-weight: bold;
    position: absolute;
    top: 1.2rem;
    right: .55rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

}

@media screen and (max-width: 620px) {
  .contBtnMenu {
    /* background: #e65f5f; */
    width: 3rem;
  }

  .contImage {
    /* background: #fdc495; */
    width: 3rem;
  }

  .contImage .image {
    width: 2rem;
    height: 2rem;
  }

  .contTitle {
    /* background: #a7f5e4; */
    width: calc(100% - 13rem);
    padding: 0;
    display: flex;
    justify-content: center;
  }

  .contTitle .title {
    font-size: 0;
  }

  .contTitle .title::after {
    content: "TecNM";
    font-size: 0.8rem;
  }

  .contTitle .desc {
    font-size: 0.7rem;
  }

  .contPerfil {
    /* background: #e71818; */
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem;
  }

  .contPerfil .imageProfile .imgProfile {
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 0.9rem;
    padding: 2px;
  }

  .notification {
    /* background: #000; */
    width: 3rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .notification .icon {
    color: var(--cuarto);
    font-size: 1.4rem;
  }

  .notification .count {
    background: var(--danger);
    width: .9rem;
    height: .9rem;
    color: var(--terciario);
    font-size: .6rem;
    font-weight: bold;
    position: absolute;
    top: 1.2rem;
    right: .55rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
}
