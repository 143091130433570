.container {
  background: var(--primario);
  width: 5vw;
  height: 100%;
  transition: all 0.5s ease;
}

.container:hover {
  width: 21vw;
}

.container:hover .option .contText {
  display: flex;
  align-items: center;
}

.menu {
  width: 100%;
  height: 100%;
}

.menuItems {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
}

.menuItems .contOptions {
  /* background: #7b8cf0; */
  width: 100%;
  height: calc(100% - 4rem);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.menu .option {
  /* background: #000; */
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  overflow: hidden;
  padding: 0 calc((5vw - 2rem) / 2);
}

.menu .option .contIcon {
  /* background: #ff7a58; */
  min-width: 2rem;
  min-height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.7rem;
  color: var(--cuarto);
  font-size: 1rem;
  transition: all 0.5s ease;
  /* margin-left: 1rem; */
}

.menu .opActive .contIcon {
  background: var(--terciario);
  color: var(--primario);
  font-size: 0.8rem;
}

.menu .option .contText {
  /* background: #000; */
  width: calc(100% - 2rem);
  height: 100%;
  display: none;
  padding-left: 0.5rem;
  overflow: hidden;
}

.menu .option .contText .text {
  color: var(--cuarto);
  transition: all 0.5s ease;
  white-space: nowrap;
}

.menu .opActive .contText .text {
  color: var(--terciario);
  font-weight: bold;
}

.menu .option:hover .contIcon {
  color: var(--terciario);
}

.menu .opActive:hover .contIcon {
  background: var(--terciario);
  color: var(--primario);
}

.menu .option:hover .contText .text {
  color: var(--terciario);
}

.menu .opActive:hover .contText .text {
  color: var(--terciario);
}

.show {
  /* transform: translateX(0%); */
  animation: animarMenuopc 1s forwards;
}

@keyframes animarMenuopc {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    /* background: #000; */
    width: 6vw;
  }

  .container:hover {
    width: 25vw;
  }

  .menu .option {
    padding: 0 calc((6vw - 2rem) / 2);
  }
}

@media screen and (max-width: 858px) {
  .container {
    width: 0%;
    height: 0;
  }

  .menu {
    width: 0%;
    padding: 0;
  }

  .menuItems {
    background: var(--primario);
    position: fixed;
    width: 100%;
    height: calc(100vh - 4rem);
    top: 4rem;
    left: -100%;
    z-index: 999;
    justify-content: space-between;
    flex-direction: column;
    transition: all 0.5s ease;
  }

  .menuItems .contOptions {
    width: 100%;
    margin: 0;
    border-radius: 0;
  }

  .menu .option {
    justify-content: flex-start;
    padding-left: 5rem;
  }

  .menuItems .contOptions .opcion:hover {
    color: var(--primario);
    background: var(--secundario);
  }

  .menu .option .contText {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 620px) {
  .menu .option {
    justify-content: flex-start;
    padding-left: 3rem;
  }
}